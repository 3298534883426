import bgLogo from '@/assets/community-bg-logo.png';
import classnames from 'classnames';
import React from 'react';

interface Props {
  src?: string;
  alt: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  rounded?: 'sm' | 'md' | 'lg' | 'full';
  className?: string;
}

export const Avatar: React.FC<Props> = ({ className, src, alt, size = 'md', rounded = 'full' }) => {
  const sizeClass = {
    '2xl': 'w-32 h-32',
    lg: 'w-10 h-10',
    md: 'w-8 h-8',
    sm: 'w-6 h-6',
    xl: 'w-16 h-16'
  }[size];

  const roundedClass = {
    full: 'rounded-full',
    lg: 'rounded-lg',
    md: 'rounded-md',
    sm: 'rounded-sm'
  }[rounded];

  return src ? (
    <img {...{ alt, src }} className={classnames('object-cover', sizeClass, roundedClass, className)} />
  ) : (
    <img className={classnames('object-cover', sizeClass, roundedClass, className)} src={bgLogo} />
  );
};
