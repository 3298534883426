import { StudentInvoiceDto } from '@billy/management-api-sdk/src/models/StudentInvoiceDto';
import classNames from 'classnames';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Column, DataTable } from './DataTable';

type Props = {
  invoices: StudentInvoiceDto[] | undefined;
};

export const StudentInvoicesList: React.FC<Props> = ({ invoices }) => {
  const latestInvoice = invoices?.reduce((latest, current) => {
    const currentDate = new Date(current.date);
    const latestDate = new Date(latest.date);
    return currentDate > latestDate ? current : latest;
  }, invoices[0]);

  const currentDue = latestInvoice?.amount || 0;

  let totalFee = 0;
  let totalDue = 0;
  let totalPaid = 0;

  invoices?.forEach((invoice) => {
    totalDue += invoice.amount;
    totalPaid += invoice.paid;
    if (invoice.narration.toUpperCase().includes('FEE')) {
      totalFee += invoice.amount;
    }
  });

  const columns: Column<StudentInvoiceDto>[] = [
    {
      accessor: 'title',
      cell: (invoice) => (
        <div className="flex flex-col whitespace-normal">
          <p className="text-md font-semibold">{invoice.narration.toUpperCase()}</p>
          <span className="text-sm opacity-60">{invoice.description}</span>
        </div>
      ),
      header: 'Title'
    },
    {
      accessor: 'status',
      cell: (invoice) => (
        <p
          className={classNames(
            'w-max rounded-full bg-opacity-10 px-4 py-1 font-semibold',
            invoice.balance <= 0 ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#DE3530] text-[#DE3530]'
          )}
        >
          {invoice.balance <= 0 ? 'Paid' : 'Not paid'}
        </p>
      ),
      filter: {
        options: [
          { label: 'Paid', value: 'paid' },
          { label: 'Not paid', value: 'notPaid' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'date',
      cell: (invoice) => <p className="font-semibold">{invoice.date}</p>,
      header: 'Date'
    },
    {
      accessor: 'amount',
      cell: (invoice) => <p className="text-md font-semibold">{invoice.amount.toFixed(2)}</p>,
      header: 'Amount (USD)'
    },
    {
      accessor: 'paid',
      cell: (invoice) => <p className="text-md font-semibold">{invoice.paid.toFixed(2)}</p>,
      header: 'Paid (USD)'
    },
    {
      accessor: 'receipt',
      cell: (invoice) => <p className="text-md font-semibold">{invoice.receipt}</p>,
      header: 'Receipt (USD)'
    },
    {
      accessor: 'balance',
      cell: (invoice) => <p className="text-md font-semibold">{invoice.balance.toFixed(2)}</p>,
      header: 'Balance (USD)'
    }
  ];

  return (
    <div className="mb-20 flex flex-col gap-14">
      <div className="flex flex-col gap-5">
        <p className="text-xl font-semibold">Overview</p>
        <ul className="grid grid-cols-4 gap-4">
          <li className="flex flex-col gap-3">
            <span className="text-md">Total Fee</span>
            <div className="flex justify-center rounded-lg bg-gray-100 px-8 py-4">
              <span className="font-semibold">
                USD <FormattedNumber minimumFractionDigits={2} value={totalFee} />
              </span>
            </div>
          </li>
          <li className="flex flex-col gap-3">
            <span className="text-md">Total Due</span>
            <div className="flex justify-center rounded-lg bg-gray-100 px-8 py-4">
              <span className="font-semibold">
                USD <FormattedNumber minimumFractionDigits={2} value={totalDue} />
              </span>
            </div>
          </li>
          <li className="flex flex-col gap-3">
            <span className="text-md">Current Due</span>
            <div className="flex justify-center rounded-lg bg-gray-100 px-8 py-4">
              <span className="font-semibold">
                USD <FormattedNumber minimumFractionDigits={2} value={currentDue} />
              </span>
            </div>
          </li>
          <li className="flex flex-col gap-3">
            <span className="text-md">Total Paid</span>
            <div className="flex justify-center rounded-lg bg-gray-100 px-8 py-4">
              <span className="font-semibold">
                USD <FormattedNumber minimumFractionDigits={2} value={totalPaid} />
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-5">
        <p className="text-xl font-semibold">History</p>

        {invoices && invoices.length > 0 ? (
          <DataTable columns={columns} data={invoices || []} />
        ) : (
          <p className="mt-10 text-center text-lg">No invoices to show</p>
        )}
      </div>
    </div>
  );
};
