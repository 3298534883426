import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { page, search, size, status, statuses, types, useQueryParams } from '@/hooks/useQueryParams';

import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { LoadingOverlay } from '@/components/Loading';
import { Navbar } from '@/components/Navbar';
import { Pagination } from '@/components/Pagination';
import { CommunitiesList } from '@/components/Table/CommunitiesList';
import { PlusIcon } from '@heroicons/react/24/solid';

const config = { page, search, size, status, statuses, types };

export const CommunitiesPage: React.FC = () => {
  const [queryParams, updateQueryParams] = useQueryParams({ config });
  const communitiesKey = `communities/find?page=${queryParams.page}&size=${queryParams.size}&statuses=${queryParams.statuses}&types=${queryParams.types}`;

  const {
    data,
    isLoading: loading,
    error,
    mutate: onReload
  } = useSWR(
    [communitiesKey, queryParams],
    () =>
      apiClient.community.findCommunities({
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: queryParams.search,
        status: queryParams.status,
        statuses: queryParams.statuses,
        types: queryParams.types
      }),
    {
      keepPreviousData: true,
      revalidateOnFocus: false
    }
  );

  const intl = useIntl();

  const isFilterActive = !!queryParams.status || !!queryParams.types || !!queryParams.search;

  const hasCommunities = data?.page.total !== 0 || isFilterActive;

  const noResultsFound = isFilterActive && data?.page.total === 0;

  const filterFieldMap: Record<string, string> = {
    status: 'statuses',
    type: 'types'
  };

  const getFilterField = (field: string) => {
    return filterFieldMap[field] || field;
  };

  const handleFilter = (field: string, values: any) => {
    const filterField = getFilterField(field);

    const filterValues = values.length > 0 ? (Array.isArray(values) ? values : [values]) : undefined;

    const updatedParams = {
      ...queryParams,
      [filterField]: filterValues,
      page: 1
    };

    updateQueryParams(updatedParams);
  };

  return (
    <Layout>
      <div className="my-5 w-full">
        <Navbar title={<FormattedMessage id={translations.pages.community.communities} />} />
      </div>

      <div className="flex items-center justify-between">
        <DebouncedInput
          as={SearchInput}
          className="w-72"
          initialValue={queryParams.search}
          onChange={(search: string) => updateQueryParams({ page: 1, search })}
          placeholder={intl.formatMessage({ id: translations.pages.community.searchCommunities })}
        />

        <Button
          appearance="primary"
          as={RouterLink}
          className="flex w-48 items-center justify-center gap-2"
          to="/communities/new"
        >
          <PlusIcon className="h-5 w-5" />
          <p>
            <FormattedMessage id={translations.buttons.createCommunity} />
          </p>
        </Button>
      </div>

      <div className="space-y-9">
        <LoadingOverlay {...{ error, loading }} className={error ? 'mt-24' : undefined}>
          <CommunitiesList
            className="mt-4"
            communities={data?.items}
            empty={!hasCommunities}
            noResults={noResultsFound}
            onFilter={handleFilter}
            onReload={onReload}
          />
        </LoadingOverlay>

        {!!data && hasCommunities && (
          <Pagination
            className="mt-9"
            currentPage={data.page.pageNumber}
            onPageChange={(page: number) => updateQueryParams({ page })}
            pageSize={data.page.pageSize}
            totalItems={data.page.total}
          />
        )}
      </div>
    </Layout>
  );
};
