import { ChevronRightIcon } from '@heroicons/react/24/solid';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { translations } from '@/locales';

import csvTemplate from '@/assets/import-students-template.csv';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { isCSV, MixedFileInput } from '@/components/Form/MixedFileInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { useRole } from '@/hooks/useRole';
import { apiClient } from '@/services/api';
import { uploadFile } from '@/utils/functions';

const maxSizeInMB = 10;

export const schema = yup
  .object({
    file: yup
      .mixed()
      .test({
        exclusive: true,
        message: translations.customValidation.maxFileSize,
        name: 'maxFileSize',
        params: { max: maxSizeInMB },
        test: (value) => !value || (isCSV(value) && value.size <= maxSizeInMB * 1024 * 1024)
      })
      .label(translations.fields.file)
  })
  .required();

const getCSVDownloadURL = () => {
  let csvData = '';

  csvTemplate.forEach((row) => {
    const rowString = row.join(',') + '\n';
    csvData += rowString;
  });

  const blob = new Blob([csvData], { type: 'text/csv' });

  const url = URL.createObjectURL(blob);

  return url;
};

export const ImportStudentsPage: React.FC = () => {
  const [uploadCompleted, setUploadCompleted] = React.useState<boolean>(false);
  const [taskId, setTaskId] = React.useState<string>();
  const { communityId } = useRole();

  const bulkImportUpload = React.useCallback(async (file: string | File, communityId: string) => {
    if (typeof file === 'string') return file;
    else {
      const intent = await apiClient.community.initiateBulkStudentsUpload({
        communityId,
        requestBody: { mimeType: file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, file);

      return intent.taskId;
    }
  }, []);

  const { data, error } = useSWR(
    taskId ? 'import-status' : null,
    () => apiClient.community.getBulkMembersUploadProgress({ communityId: communityId ?? '', taskId: taskId! }),
    {
      onSuccess: (newData) => {
        setUploadCompleted(newData.progress === 1);
      },
      refreshInterval: uploadCompleted ? undefined : 3000,
      shouldRetryOnError: false
    }
  );

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (!communityId) {
        return;
      }
      const taskId = await bulkImportUpload(values.file, communityId);
      setTaskId(taskId);
    },
    [bulkImportUpload, communityId]
  );

  const failedRecords = React.useMemo(() => data?.results.filter((student) => student.status === 'failed'), [data]);

  const completedRecords = React.useMemo(
    () => data?.results.filter((student) => student.status === 'completed' || student.status === 'skipped'),
    [data]
  );

  const hasErrors = !!uploadCompleted && !!failedRecords?.length;

  return (
    <Layout>
      <div className="flex items-center gap-5">
        <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
          <FormattedMessage id={translations.pages.community.communities} />
        </Link>

        <ChevronRightIcon className="w-5 text-sm text-gray-400" />

        <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="#">
          <FormattedMessage id={translations.pages.students.importStudents} />
        </Link>
      </div>

      <Form className="flex h-full items-center justify-center pt-28" {...{ onSubmit, schema }}>
        {({ submitting, submitSucceeded, form, values }) => (
          <div className="max-w-md space-y-10">
            <div className="flex justify-between">
              <div className="gap-3 text-2xl font-semibold">
                <FormattedMessage id={translations.pages.students.importStudents} />
              </div>
            </div>

            <div className="space-y-6">
              <div className="text-sm text-gray-500">
                <FormattedMessage
                  id={translations.pages.students.templateInfo}
                  values={{
                    downloadTemplate: (
                      <Link
                        as="a"
                        className="font-normal text-indigo-600"
                        download="import-students-template.csv"
                        href={getCSVDownloadURL()}
                      >
                        <FormattedMessage id={translations.pages.students.downloadTemplate} />
                      </Link>
                    )
                  }}
                />
              </div>

              <div>
                <div>
                  <FormattedMessage id={translations.pages.students.goodToKnow} />
                </div>

                <div className="mt-2 text-sm text-gray-500">
                  <FormattedMessage id={translations.pages.students.goodToKnowInfo} />
                </div>
              </div>
            </div>

            <div className="flex h-full w-full flex-col space-y-6">
              <span>
                <FormattedMessage id={translations.fields.uploadStudents} />
              </span>
              <div
                className={classnames('flex w-full flex-row items-center justify-center rounded-lg', {
                  'border-2 border-dashed border-[#DEDCD3]': !values.file
                })}
              >
                <ValidatedField
                  accept=".csv"
                  as={MixedFileInput}
                  className="flex w-full items-center justify-center"
                  disabled={submitting || submitSucceeded}
                  field={InputWithLabel}
                  fieldClassName="flex flex-col gap-4 items-center justify-center w-full"
                  fieldProps={{
                    className: 'flex flex-col space-y-1 w-full'
                  }}
                  id="file"
                  name="file"
                  onRemove={() => null}
                  successfullUpload={false}
                  uploadProgress={1}
                >
                  {!values.file && (
                    <div className="flex flex-col items-center justify-center px-6 pb-6 pt-4">
                      <Button
                        appearance="basic"
                        className="pointer-events-none flex gap-2 border-none text-[#ED6F4C] shadow-none"
                        type="button"
                      >
                        <FormattedMessage id={translations.buttons.uploadFile} />
                      </Button>

                      <div className=" text-sm font-normal leading-4 text-[#79747E]">
                        <FormattedMessage id={translations.pages.students.onlyCsv} />
                      </div>
                    </div>
                  )}
                </ValidatedField>
              </div>

              <div className="flex w-full flex-row items-center justify-between gap-4">
                <Button
                  appearance="basicSecondary"
                  className={classnames('w-48', { hidden: submitSucceeded || !values.file })}
                  onClick={() => {
                    form.reset();
                  }}
                  type="button"
                >
                  <FormattedMessage id={translations.buttons.cancel} />
                </Button>

                <Button
                  appearance="primary"
                  as={LoadingButton}
                  className={classnames('w-48', { hidden: submitSucceeded || !values.file })}
                  loading={submitting}
                  type="submit"
                >
                  <FormattedMessage id={translations.buttons.confirmStudents} />
                </Button>
              </div>
            </div>
          </div>
        )}
      </Form>

      {!!failedRecords?.length && <div>{JSON.stringify(failedRecords)}</div>}
      {!!completedRecords?.length && <div>{JSON.stringify(completedRecords)}</div>}
      {!!hasErrors && (
        <div>
          {JSON.stringify(hasErrors)} {error}
        </div>
      )}
    </Layout>
  );
};
