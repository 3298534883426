import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';

import { useModal } from '@/hooks/useModal';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { Modal } from '@/components/Modal';
import { InvitationModal } from '@/components/Modal/InvitationModal';

import { Navbar } from '@/components/Navbar';
import { MemberProfile } from '@/components/Profile';
import { Column, DataTable } from '@/components/Table/DataTable';
import { Status } from '@/constants';
import { CommunityManagerDto } from '@billy/management-api-sdk';
import { PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { CommunityPage } from './CommunityPage';

export const emailSchema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.emailAddress)
  })
  .required();

export const CommunityPageDetails: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const { communityId } = useParams();

  const {
    data: community,
    isLoading: loading,
    error,
    mutate: onReload
  } = useSWR(
    ['get-community', communityId],
    () => apiClient.community.getCommunityDetails({ communityId: communityId! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  // so the ManagersList calling on reload won't trigger CommunityPage re-render
  const onReloadCallback = React.useCallback(() => onReload(), [onReload]);

  const handleManagerStatus = async (manager: CommunityManagerDto) => {
    manager.status === Status.ACTIVE
      ? await apiClient.community.suspendCommunityManagers({
          communityId: communityId!,
          requestBody: { userIds: [manager.id] }
        })
      : await apiClient.community.addCommunityManagers({
          communityId: communityId!,
          requestBody: { emails: [manager.email] }
        });

    onReloadCallback();
  };

  const invitationModal = useModal(InvitationModal);

  const columns: Column<CommunityManagerDto>[] = [
    {
      accessor: 'name',
      cell: (manager: CommunityManagerDto) => (
        <MemberProfile
          email={manager.email}
          firstName={manager.firstName}
          lastName={manager.lastName}
          src={manager.image}
        />
      ),
      header: 'Name'
    },
    {
      accessor: 'dateAdded',
      cell: (manager: CommunityManagerDto) => (
        <p className="font-semibold">
          <FormattedDate day="2-digit" month="2-digit" value={manager.createdAt} year="numeric" />
        </p>
      ),
      header: 'Date added'
    },
    {
      accessor: 'status',
      cell: (manager: CommunityManagerDto) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <p
            className={classNames(
              'w-max rounded-full bg-opacity-10 px-4 py-1 font-semibold',
              manager.status == 'active'
                ? 'bg-[#52B642] text-[#52B642]'
                : /*                 : false
                  ? 'bg-[#F1A660] text-[#F1A660]' */
                  'bg-[#79747E] text-[#79747E]'
            )}
          >
            {manager.status}
          </p>
        </div>
      ),
      filter: {
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'actions',
      cell: (manager: CommunityManagerDto) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <Button
            className={classNames(
              'w-full rounded-xl bg-opacity-10 px-8 py-2 font-bold hover:bg-opacity-30',
              manager.status !== Status.ACTIVE ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#DE3530] text-[#DE3530]'
            )}
            onClick={() => handleManagerStatus(manager)}
            type="button"
          >
            {manager.status == Status.ACTIVE ? 'Suspend' : 'Reactivate'}
          </Button>
        </div>
      ),
      header: ''
    }
  ];
  return (
    <Layout>
      <LoadingOverlay {...{ error, loading }}>
        {!!isAdmin && (
          <div className="mb-6 flex gap-5">
            <div className="my-5 flex w-full justify-between">
              <div className="flex items-center">
                <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
                  <FormattedMessage id={translations.pages.community.communities} />
                </Link>

                <ChevronRightIcon className="w-5 text-gray-400" />

                <Link as={RouterLink} className="text-sm font-medium text-[#ED6F4C]" to="#">
                  <FormattedMessage id={community?.name || translations.pages.community.newCommunity} />
                </Link>
              </div>
              <div>
                <Navbar title="" />
              </div>
            </div>
          </div>
        )}

        <CommunityPage {...{ community, onReloadCallback }} />

        {!!isAdmin && (
          <div className="m-auto my-10 flex w-full max-w-7xl flex-col gap-8">
            <div className="flex w-full items-center justify-between">
              <p className="text-xl font-semibold">Managers</p>
              <Button
                appearance="primary"
                className="flex w-48 items-center justify-center gap-2"
                onClick={() =>
                  invitationModal
                    .open()
                    .then()
                    .catch(() => null)
                }
                type="button"
              >
                <PlusIcon className="h-5 w-5" />
                <p>Add manager</p>
              </Button>
            </div>
            {community?.managers && community?.managers.length <= 0 ? (
              <EmptyList
                description={<FormattedMessage id={translations.emptyStates.addManager} />}
                title={<FormattedMessage id={translations.emptyStates.noManagers} />}
              >
                <Button
                  appearance="primary"
                  className="flex w-48 items-center justify-center gap-2"
                  onClick={() =>
                    invitationModal
                      .open()
                      .then()
                      .catch(() => null)
                  }
                  type="button"
                >
                  <PlusIcon className="h-5 w-5" />
                  <p>Add manager</p>
                </Button>
              </EmptyList>
            ) : (
              <DataTable columns={columns} data={community?.managers || []} />
            )}
          </div>
        )}

        <Modal
          onInvite={async (email) => {
            await apiClient.community
              .addCommunityManagers({
                communityId: community!.id,
                requestBody: { emails: [email] }
              })
              .then(() => onReload());
          }}
          schema={emailSchema}
          {...invitationModal.props}
          description={<FormattedMessage id={translations.pages.community.inviteManager} />}
          successMessage={<FormattedMessage id={translations.pages.community.invitedManager} />}
          title={<FormattedMessage id={translations.buttons.addManager} />}
        />
      </LoadingOverlay>
    </Layout>
  );
};
