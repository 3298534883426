import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Auth } from '@/containers/use-auth';

import { Button } from '@/components/Button';
import { CenteredBoxLayout } from '@/components/CenteredBoxLayout';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { PasswordInput } from '@/components/Form/PasswordInput';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { SubmitFeedback } from '@/components/SubmitFeedback';

import backgroundImg from '@/assets/background-login.png';
import { Input } from '@/components/Form/Input';

export const schema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.emailAddress),
    password: yup.string().trim().required().label(translations.fields.password)
  })
  .required();

export const LoginPage: React.FC = () => {
  const [rememberMe, setRememberMe] = React.useState(false);
  const { loginWithPassword } = Auth.useContainer();

  const onSubmit = React.useCallback(
    async ({ email, password }: yup.InferType<typeof schema>) => await loginWithPassword({ email, password }),
    [loginWithPassword]
  );

  return (
    <CenteredBoxLayout backgroundImg={backgroundImg} title={<FormattedMessage id={translations.pages.login.title} />}>
      <Form {...{ onSubmit, schema }}>
        {({ submitting, dirtySinceLastSubmit, submitError, submitSucceeded }) => (
          <div className="space-y-4">
            <ValidatedField
              field={Input}
              id="email"
              label={<FormattedMessage id={translations.fields.emailAddress} />}
              name="email"
              placeholder="E-mail"
              readOnly={submitting}
              type="email"
            />

            <ValidatedField
              field={PasswordInput}
              id="password"
              label={<FormattedMessage id={translations.fields.password} />}
              name="password"
              placeholder="Password"
              readOnly={submitting}
              type="password"
            />

            <div className="mt-4 flex justify-between">
              <ValidatedField
                checked={rememberMe}
                field={Checkbox}
                id="remember"
                label={<FormattedMessage id={translations.pages.login.remember} />}
                name="remember"
                onClick={() => setRememberMe(!rememberMe)}
                readOnly={submitting}
                type="checkbox"
              />

              <Link as={RouterLink} className="text-[#FEA68A] duration-300 hover:text-[#ED6F4C]" to="/forgot-password">
                <FormattedMessage id={translations.pages.login.forgot} />
              </Link>
            </div>

            <Button appearance="primary" as={LoadingButton} className="w-full" loading={submitting} type="submit">
              <FormattedMessage id={translations.pages.login.cta} />
            </Button>

            <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
          </div>
        )}
      </Form>
    </CenteredBoxLayout>
  );
};
