import { CommunityDto } from '@billy/management-api-sdk';
import React from 'react';

import communityLogoDefault from '@/assets/community-bg-logo.png';
import { Button } from '@/components/Button';
import { SchoolDetails } from '@/components/SchoolDetails';
import { apiClient } from '@/services/api';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

export const CommunityPage: React.FC<{
  community?: CommunityDto;
  onReloadCallback: () => Promise<CommunityDto | undefined>;
}> = ({ community, onReloadCallback }) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="inline-flex items-center">
          <img className={'h-28 w-28 rounded-full object-cover'} src={community?.image || communityLogoDefault} />

          <div className="flex flex-col gap-2 px-4">
            <p className="font-medium text-neutral-800">
              {community?.name}
              <span
                className={classNames(
                  'ml-4 rounded-full bg-opacity-10 px-4 py-1 font-semibold',
                  community?.active ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#79747E] text-[#79747E]'
                )}
              >
                {community?.active ? 'Active' : 'Inactive'}
              </span>
            </p>

            <p className="w-max rounded-lg bg-[#ED6F4C] bg-opacity-10 px-4 py-1 text-[#ED6F4C]">
              {community?.type == 'school' ? 'Education' : 'Default'}
            </p>

            <p className="line-clamp-3 max-w-md text-neutral-500">{community?.description}</p>
          </div>
        </div>

        <div className="flex h-max w-max items-center gap-4">
          <Button
            className={classNames(
              'w-max rounded-xl bg-opacity-10 font-bold hover:bg-opacity-30',
              !community?.active ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#DE3530] text-[#DE3530]'
            )}
            onClick={async () => {
              community?.active
                ? await apiClient.community.suspendCommunity({ communityId: community.id })
                : await apiClient.community.reactivateCommunity({ communityId: community!.id });

              onReloadCallback();
            }}
            type="button"
          >
            {community?.active ? 'Suspend' : 'Reactivate'}
          </Button>

          <Button
            appearance="basicSecondary"
            as={RouterLink}
            className="w-max hover:bg-gray-200 disabled:opacity-50"
            to={`/communities/${community?.id}/edit`}
          >
            Edit general information
          </Button>
        </div>
      </div>

      <ul className="my-14 flex h-max w-full gap-5">
        <li className="flex flex-col gap-4">
          <p>Background Image</p>
          <div className="h-40 w-80 rounded-lg">
            <img className={'h-full rounded-lg object-cover'} src={community?.membershipCardImage} />
          </div>
        </li>
        {community?.type === 'school' && <SchoolDetails community={community} onReloadCallback={onReloadCallback} />}
      </ul>
    </div>
  );
};
