import imgPlaceholder from '@/assets/user-placeholder.png';
import { Auth } from '@/containers/use-auth';
import { ArrowRightOnRectangleIcon, ChevronDownIcon, ChevronUpIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from './Button';

export type Props = {
  title: React.ReactNode;
};

export const Navbar = ({ title }: React.PropsWithChildren<Props>) => {
  const { context, logout } = Auth.useContainer();
  const [showNavbar, setShowNavbar] = React.useState(false);

  const navigate = useNavigate();

  const toggleShowNavbar = React.useCallback(() => {
    setShowNavbar(!showNavbar);
  }, [showNavbar]);

  const Icon = showNavbar ? ChevronUpIcon : ChevronDownIcon;

  return (
    <div className="flex items-center justify-between">
      <p className="text-2xl font-semibold">{title}</p>

      <div className="flex items-center justify-between gap-3">
        <div className="h-10 w-10">
          <img
            alt="profile icon"
            className="h-full w-full rounded-full object-cover"
            src={context?.image || imgPlaceholder}
          />
        </div>
        <div className="relative">
          <div className="flex cursor-pointer items-center gap-1" onClick={toggleShowNavbar}>
            <p className="text-sm font-medium">{`${context?.firstName} ${context?.lastName}`}</p>
            <Icon className="h-4 w-4 " />
          </div>

          {showNavbar && (
            <div className="absolute right-0 top-10 z-10 w-40 rounded-lg border bg-white duration-500">
              <Button
                appearance="basic"
                className="flex w-full items-center justify-between gap-1 border-0 px-4 py-2 text-sm text-black opacity-80 hover:bg-transparent hover:opacity-100 disabled:bg-transparent disabled:opacity-50"
                onClick={() => navigate('/profile')}
                shouldUseShadow={false}
                type="button"
              >
                <p>My settings</p>
                <Cog6ToothIcon className="h-5 w-5" />
              </Button>
              <hr />
              <Button
                appearance="basic"
                className="flex w-full items-center justify-between gap-1 border-0 px-4 py-2 text-sm text-[#FF231C] opacity-80 hover:bg-transparent hover:opacity-100 disabled:bg-transparent disabled:opacity-50"
                onClick={logout}
                shouldUseShadow={false}
                type="button"
              >
                <p>Log out</p>
                <ArrowRightOnRectangleIcon className="h-5 w-5" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
