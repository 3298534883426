import React from 'react';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { Input } from '@/components/Form/Input';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Navbar } from '@/components/Navbar';
import { useRole } from '@/hooks/useRole';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

export const schema = yup.object({
  address: yup.string().max(512).required(),
  birthDate: yup.string().required(),
  currentAcademicYear: yup.string().max(10).required(),
  division: yup.string().max(50).required(),
  fatherName: yup.string().max(256).required(),
  feeCollectionStartDate: yup.string().required(),
  gender: yup.string().max(50).required(),
  joiningAcademicYear: yup.string().max(10).required(),
  motherEmail: yup.string().max(256).required(),
  motherMobile: yup.string().max(20).required(),
  motherName: yup.string().max(256).required(),
  name: yup.string().max(256).required(),
  nationality: yup.string().max(50).required(),
  religion: yup.string().max(50).required(),
  status: yup.string().required(),
  studentClass: yup.string().max(50).required()
});

export const CreateOrEditStudentPage: React.FC = () => {
  const navigate = useNavigate();
  const { studentCode } = useParams();
  const { communityId, isAdmin } = useRole();
  const isNew = !studentCode;

  const {
    data: studentData,
    isLoading: studentLoading,
    error: studentError
  } = useSWR(
    isNew ? null : ['schools/find-student', communityId, studentCode],
    () => apiClient.school.findStudent({ communityId: communityId ?? '', studentCode: studentCode! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const initialValues = React.useMemo(() => {
    return { ...studentData };
  }, [studentData]);

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      if (isNew) {
        await apiClient.student.createStudent({
          communityId: communityId ?? '',
          requestBody: { ...values }
        });
      } else {
        await apiClient.student.updateStudent({
          requestBody: { ...values },
          studentCode
        });
      }

      navigate(-1);
    },
    [communityId, isNew, navigate, studentCode]
  );

  return (
    <Layout>
      <div className="flex items-center justify-between gap-4 py-10">
        <div className="flex items-center gap-5">
          <Link
            as={RouterLink}
            className="text-sm font-medium text-gray-500"
            to={isAdmin ? `/communities/${communityId ?? ''}/students` : `/students`}
          >
            Students
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <LoadingOverlay {...{ error: studentError, loading: studentLoading }} displayLoader>
            <span className="text-sm font-medium text-[#ED6F4C]"> {isNew ? 'New' : 'Edit'}</span>
          </LoadingOverlay>
        </div>

        <div className="flex items-center gap-2">
          <Navbar title="" />
        </div>
      </div>

      <LoadingOverlay {...{ error: studentError, loading: studentLoading }} displayLoader>
        <div className="mx-auto flex w-[90%] flex-col justify-between">
          <h1 className="text-2xl font-semibold">{isNew ? 'New Student' : 'Edit Student'}</h1>

          <Form className="mt-10 flex w-full grow flex-col" {...{ initialValues, onSubmit, schema }}>
            {({ submitting }) => (
              <>
                <div className="flex flex-col gap-5">
                  <p className="text-xl font-semibold">General</p>
                  <div className="grid grid-cols-3 gap-4">
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Full name</span>
                      <ValidatedField
                        field={Input}
                        id="name"
                        name="name"
                        placeholder="Student name"
                        readOnly={submitting}
                      />
                    </label>

                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Gender</span>
                      <ValidatedField
                        field={Input}
                        id="gender"
                        name="gender"
                        placeholder="Student gender (e.g. Male or Female)"
                        readOnly={submitting}
                      />
                    </label>

                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Religion</span>
                      <ValidatedField
                        field={Input}
                        id="religion"
                        name="religion"
                        placeholder="Student religion"
                        readOnly={submitting}
                      />
                    </label>

                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Nationaly</span>
                      <ValidatedField
                        field={Input}
                        id="nationality"
                        name="nationality"
                        placeholder="Student nationality"
                        readOnly={submitting}
                      />
                    </label>

                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Birth date</span>
                      <ValidatedField
                        field={Input}
                        id="birthDate"
                        name="birthDate"
                        placeholder="Student's date of birth"
                        readOnly={submitting}
                      />
                    </label>

                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Father name</span>
                      <ValidatedField
                        field={Input}
                        id="fatherName"
                        name="fatherName"
                        placeholder="Student father's name"
                        readOnly={submitting}
                      />
                    </label>

                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Mother name</span>
                      <ValidatedField
                        field={Input}
                        id="motherName"
                        name="motherName"
                        placeholder="Student mother's name"
                        readOnly={submitting}
                      />
                    </label>
                  </div>
                </div>

                <div className="mt-10 flex flex-col gap-5">
                  <p className="text-xl font-semibold">School informations</p>
                  <div className="grid grid-cols-2 gap-4">
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Class</span>
                      <ValidatedField
                        field={Input}
                        id="studentClass"
                        name="studentClass"
                        placeholder="Student class"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Division</span>
                      <ValidatedField
                        field={Input}
                        id="division"
                        name="division"
                        placeholder="Student division class"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Joining date</span>
                      <ValidatedField
                        field={Input}
                        id="joiningAcademicYear"
                        name="joiningAcademicYear"
                        placeholder="Student joining Academic Year (e.g. 2024)"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Current academic year</span>
                      <ValidatedField
                        field={Input}
                        id="currentAcademicYear"
                        name="currentAcademicYear"
                        placeholder="Student current academic year (e.g. 2024-2025)"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Fee collection start date</span>
                      <ValidatedField
                        field={Input}
                        id="feeCollectionStartDate"
                        name="feeCollectionStartDate"
                        placeholder="Student fee collection start date (e.g. 2024)"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Joining class</span>
                      <ValidatedField
                        field={Input}
                        id="studentClass"
                        name="studentClass"
                        placeholder="Student class"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Status</span>
                      <ValidatedField
                        field={Input}
                        id="status"
                        name="status"
                        placeholder="Student status (e.g. Studying, completed...)"
                        readOnly={submitting}
                      />
                    </label>
                  </div>
                </div>

                <div className="mt-10 flex flex-col gap-5">
                  <p className="text-xl font-semibold">Contact</p>
                  <ul className="grid grid-cols-2 gap-4">
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Mother email</span>
                      <ValidatedField
                        field={Input}
                        id="motherEmail"
                        name="motherEmail"
                        placeholder="Student mother's email"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Mother mobile</span>
                      <ValidatedField
                        field={Input}
                        id="motherMobile"
                        name="motherMobile"
                        placeholder="Student mother's mobile"
                        readOnly={submitting}
                      />
                    </label>
                    <label className="flex cursor-pointer flex-col gap-2">
                      <span className="text-md opacity-50">Address</span>
                      <ValidatedField
                        field={Input}
                        id="address"
                        name="address"
                        placeholder="Student address"
                        readOnly={submitting}
                      />
                    </label>
                  </ul>
                </div>

                <div className="my-10 flex gap-10">
                  <Button
                    appearance="basicSecondary"
                    className="mt-6 w-full hover:bg-gray-200 disabled:opacity-50"
                    onClick={() => navigate(-1)}
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    appearance="primary"
                    as={LoadingButton}
                    className="mt-6 w-full"
                    loading={submitting}
                    type="submit"
                  >
                    {isNew ? 'Create student' : 'Update student'}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </LoadingOverlay>
    </Layout>
  );
};
