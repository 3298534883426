/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateStudentDto } from '../models/CreateStudentDto';
import type { StudentDto } from '../models/StudentDto';
import type { StudentPageDto } from '../models/StudentPageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StudentService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create a student.
   * @returns StudentDto Returns the created student.
   * @throws ApiError
   */
  public createStudent({
communityId,
requestBody,
}: {
/**
 * The id of the community.
 */
communityId: string,
requestBody: CreateStudentDto,
}): CancelablePromise<StudentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/students/create',
      query: {
        'communityId': communityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * update a student.
   * @returns StudentDto Returns the updated student.
   * @throws ApiError
   */
  public updateStudent({
studentCode,
requestBody,
}: {
/**
 * The code of the student.
 */
studentCode: string,
requestBody: CreateStudentDto,
}): CancelablePromise<StudentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/students/update',
      query: {
        'studentCode': studentCode,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return the details of a student.
   * @returns StudentDto Returns the student.
   * @throws ApiError
   */
  public getStudentDetails({
studentCode,
}: {
/**
 * The code of the student.
 */
studentCode: string,
}): CancelablePromise<StudentDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/students/get-details',
      query: {
        'studentCode': studentCode,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Return a page of students.
   * @returns StudentPageDto Returns a page of student.
   * @throws ApiError
   */
  public findStudents({
communityId,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * Id of the community.
 */
communityId?: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<StudentPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/students/find',
      query: {
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * delete student.
   * @returns void 
   * @throws ApiError
   */
  public deleteStudent({
studentCode,
}: {
/**
 * The code of the student.
 */
studentCode: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/students/delete',
      query: {
        'studentCode': studentCode,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
