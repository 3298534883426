import { useModal } from '@/hooks/useModal';
import { apiClient } from '@/services/api';
import { CommunityDto } from '@billy/management-api-sdk';
import React from 'react';
import { useNavigate } from 'react-router';
import useSWR from 'swr';
import { Button } from './Button';
import { LoadingOverlay } from './Loading';
import { Modal } from './Modal';
import { SelectSchoolModal } from './Modal/SelectSchoolModal';

export const SchoolDetails = ({
  community
}: {
  community: CommunityDto;
  onReloadCallback: () => Promise<CommunityDto | undefined>;
}) => {
  const navigate = useNavigate();
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [isLoadingImportGuardians, setIsLoadingImportGuardians] = React.useState(false);

  const importModal = useModal(SelectSchoolModal);

  const {
    isLoading: schoolLoading,
    data: schoolData,
    mutate: onReload
  } = useSWR(['schools/get-details', { communityId: community.id }], async () => {
    try {
      const response = await apiClient.school.getSchoolDetails({
        communityId: community!.id
      });
      setIsLoadingImportGuardians(false);
      return response;
    } catch (err) {
      console.log(err);
    }
  });

  const onImportFromTemplate = () => {
    localStorage.setItem('communityId', community.id);
    navigate('/import-students');
  };

  return (
    <>
      <li className="flex flex-col gap-4">
        <p>Students</p>
        <div className="flex h-40 w-60 items-center justify-center rounded-lg bg-gray-100 p-5">
          <LoadingOverlay displayLoader loading={schoolLoading || isLoadingImportGuardians}>
            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-xl font-bold">{schoolData?.studentsCount}</p>
              {!schoolData?.studentsCount || 0 > 0 ? (
                <Button
                  appearance="primary"
                  className="flex w-48 items-center justify-center gap-2 font-semibold"
                  onClick={() => {
                    importModal.open();
                    setShowImportModal(true);
                  }}
                  type="button"
                >
                  Import school students
                </Button>
              ) : (
                <Button
                  appearance="basicSecondary"
                  className="w-max bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
                  onClick={() => navigate(`/communities/${community?.id}/students`)}
                  type="button"
                >
                  View all students
                </Button>
              )}
            </div>
          </LoadingOverlay>
        </div>
      </li>
      <li className="flex flex-col gap-4">
        <p>Guardians</p>
        <div className="flex h-40 w-60 flex-col items-center justify-center rounded-lg bg-gray-100 p-5">
          <LoadingOverlay displayLoader loading={schoolLoading || isLoadingImportGuardians}>
            <div className="flex flex-col items-center justify-center gap-2">
              {!schoolData?.parentsCount ? (
                <>
                  <Button
                    appearance="primary"
                    className="flex w-48 items-center justify-center gap-2 font-semibold"
                    onClick={() => {
                      setIsLoadingImportGuardians(true);
                      apiClient.school.importGuardians({ communityId: community.id }).then(() => onReload());
                    }}
                    type="button"
                  >
                    Invite guardians to join community
                  </Button>
                </>
              ) : (
                <>
                  <p className="text-xl font-bold">{schoolData?.parentsCount ?? 0}</p>
                  <Button
                    appearance="basicSecondary"
                    className="w-max bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
                    onClick={() => navigate(`/communities/${community?.id}/guardians`)}
                    type="button"
                  >
                    View all guardians
                  </Button>
                </>
              )}
            </div>
          </LoadingOverlay>
        </div>
      </li>
      {showImportModal && (
        <Modal
          {...importModal.props}
          communityId={community.id}
          onClose={() => setShowImportModal(false)}
          onImportFromTemplate={onImportFromTemplate}
        />
      )}
    </>
  );
};
