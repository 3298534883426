import React from 'react';

import { Button } from '@/components/Button';
import { MemberDto } from '@billy/management-api-sdk';

import { apiClient } from '@/services/api';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import * as yup from 'yup';
import { Form } from '../Form';
import { Input } from '../Form/Input';
import { PasswordInput } from '../Form/PasswordInput';
import { ValidatedField } from '../Form/ValidatedField';
import { LoadingButton } from '../LoadingButton';
import { SubmitFeedback } from '../SubmitFeedback';
import { InstanceProps } from './Modal';
import { ModalLayout } from './ModalLayout';

const schema = yup.object({
  VC: yup.string().required(),
  password: yup.string().required(),
  schoolCode: yup.string().required(),
  username: yup.string().required()
});

interface ModalProps extends InstanceProps<undefined, Pick<MemberDto, 'email' | 'firstName' | 'id'>> {
  communityId: string;
  onImportFromTemplate(): void;
}

export const SelectSchoolModal: React.FC<ModalProps> = ({ className, communityId, onImportFromTemplate, onClose }) => {
  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await apiClient.school.setImportSchool({ requestBody: { communityId, ...values } });
      onClose();
    },
    [communityId, onClose]
  );

  return (
    <ModalLayout className={classNames(className, 'max-w-md px-0 py-0')}>
      <div className="flex flex-col items-center gap-4 p-6">
        <>
          <Form className="mt-10 flex w-full grow flex-col" {...{ onSubmit, schema }}>
            {({ submitting, submitError, submitSucceeded, dirtySinceLastSubmit }) => (
              <>
                <span className="w-full text-left text-2xl font-bold capitalize">Import school students</span>
                <span className="text-md w-full text-left font-normal">Select a school in our database</span>

                <div className="flex items-center justify-center gap-3 bg-[#FEF7E0] p-3">
                  <div className="bg-status-warning text-neutral-black h-fit w-fit rounded-full p-2 font-normal">
                    <ExclamationTriangleIcon className="stroke-neutral-white h-5 w-5 fill-none" />
                  </div>
                  <span>
                    ATTENTION: Once you select a school, you can not undo this action. If you need to change it, you
                    will need to delete the community.
                  </span>
                </div>
                <div className="my-4 grid grid-cols-2 grid-rows-2 gap-4">
                  <ValidatedField
                    field={Input}
                    id="username"
                    name="username"
                    placeholder="School Username"
                    readOnly={submitting}
                  />
                  <ValidatedField
                    field={PasswordInput}
                    id="password"
                    name="password"
                    placeholder="School Password"
                    readOnly={submitting}
                  />
                  <ValidatedField
                    field={Input}
                    id="schoolCode"
                    name="schoolCode"
                    placeholder="School Code"
                    readOnly={submitting}
                  />
                  <ValidatedField field={Input} id="VC" name="VC" placeholder="School VC" readOnly={submitting} />
                </div>
                <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />

                <Button
                  appearance="primary"
                  as={LoadingButton}
                  className="mb-2 w-full"
                  loading={submitting}
                  type="submit"
                >
                  Confirm School
                </Button>

                <Button
                  appearance="basicSecondary"
                  className="mt-4 flex w-full items-center justify-center gap-2 hover:bg-gray-200"
                  disabled={submitting}
                  onClick={onImportFromTemplate}
                  type="button"
                >
                  Import from file template
                </Button>
              </>
            )}
          </Form>
        </>
      </div>
    </ModalLayout>
  );
};
