import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { apiClient } from '@/services/api';

import { translations } from '@/locales';
import { uploadFile } from '@/utils/functions';

import { useModal } from '@/hooks/useModal';
import { useRole } from '@/hooks/useRole';

import { Avatar } from '@/components/Avatar';
import { Button } from '@/components/Button';
import { Form } from '@/components/Form';
import { getSourceFromPossibleValue, ImageFileInput, ImageValue } from '@/components/Form/ImageFileInput';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { MaxLengthIndicator } from '@/components/Form/MaxLengthIndicator';
import { Select } from '@/components/Form/Select';
import { TextArea } from '@/components/Form/TextArea';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { ConfirmationModal, deleteIcon } from '@/components/Modal/ConfirmationModal';
import { SubmitFeedback } from '@/components/SubmitFeedback';

const maxSizeInMB = 10;
export const minLogoWidth = 512;
export const minLogoHeight = 512;

export const schema = yup.object({
  categoryId: yup.string().trim().required().label(translations.fields.category),
  description: yup.string().trim().max(800).required().label(translations.fields.description),
  image: yup
    .mixed<ImageValue | string>()
    .test({
      exclusive: true,
      message: translations.customValidation.maxFileSize,
      name: 'maxFileSize',
      params: { max: maxSizeInMB },
      test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
    })
    .test({
      exclusive: true,
      message: translations.customValidation.minImageResolution,
      name: 'minImageResolution',
      params: { height: minLogoHeight, width: minLogoWidth },
      test: (value) =>
        typeof value === 'string' ||
        !value?.file ||
        (value.image.width >= minLogoWidth && value.image.height >= minLogoHeight)
    })
    .required()
    .label(translations.fields.attachment),
  name: yup.string().trim().required().label(translations.fields.benefitName),
  url: yup
    .string()
    .url()
    .matches(/^https:\/\/.*/, translations.customValidation.validHttps)
    .required()
    .label(translations.fields.link)
});

export const BenefitDetailsPage: React.FC = () => {
  const { benefitId } = useParams();
  const { community } = useRole();

  const isNew = !benefitId;

  const {
    data: loadedBenefit,
    isLoading: loadingBenefits,
    error: benefitError
  } = useSWR(
    isNew ? null : ['get-benefit-details', benefitId],
    () => apiClient.benefit.getBenefitDetails({ benefitId: benefitId! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const {
    data: categories,
    isLoading: loadingCategories,
    error: categoriesError
  } = useSWR(
    'get-categories',
    () =>
      apiClient.benefit.getBenefitCategories({
        communityId: community!.id
      }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const benefitLogoUpload = React.useCallback(async (logo: string | ImageValue, communityId: string) => {
    if (typeof logo === 'string') return logo;
    else {
      const intent = await apiClient.benefit.initiateBenefitLogoUpload({
        communityId,
        requestBody: { mimeType: logo.file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, logo.file);

      return intent.asset;
    }
  }, []);

  const categoriesItems = React.useMemo(
    () =>
      categories?.map((category) => {
        return { name: category.name, value: category.id };
      }),
    [categories]
  );

  const initialValues = loadedBenefit;

  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      isNew
        ? await benefitLogoUpload(values.image, community!.id).then((image) =>
            apiClient.benefit
              .createBenefit({ communityId: community!.id, requestBody: { ...values, image } })
              .then((benefit) => navigate(`/benefits/${benefit.id}`))
          )
        : await benefitLogoUpload(values.image, community!.id).then((image) =>
            apiClient.benefit.updateBenefitDetails({ benefitId, requestBody: { ...values, image } })
          );
    },
    [benefitId, benefitLogoUpload, community, isNew, navigate]
  );

  const intl = useIntl();

  const confirmationModal = useModal(ConfirmationModal);

  return (
    <Layout>
      <LoadingOverlay error={benefitError || categoriesError} loading={loadingBenefits || loadingCategories}>
        <div className="flex items-center gap-5">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/benefits">
            <FormattedMessage id={translations.pages.benefits.benefits} />
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <Link as={RouterLink} className="truncate text-sm font-medium text-gray-500" to="#">
            {initialValues?.name || <FormattedMessage id={translations.pages.benefits.newBenefit} />}
          </Link>
        </div>

        <Form className="mt-5 flex grow flex-col" {...{ initialValues, onSubmit, schema }}>
          {({ submitting, form, values, submitSucceeded, submitError, dirtySinceLastSubmit }) => (
            <div className="flex grow flex-col justify-between">
              <div className="space-y-6">
                <div className="text-2xl font-semibold">
                  <div className="flex justify-between">
                    {values?.name?.trim() || initialValues?.name?.trim() || (
                      <FormattedMessage id={translations.pages.benefits.newBenefit} />
                    )}

                    {!isNew && (
                      <Button
                        appearance="deleteSecondary"
                        onClick={() => {
                          confirmationModal.open().catch(() => null);
                        }}
                        type="button"
                      >
                        <span className="whitespace-nowrap">
                          <FormattedMessage id={translations.buttons.deleteBenefit} />
                        </span>
                      </Button>
                    )}
                  </div>

                  {!isNew && (
                    <div className="text-sm font-normal text-gray-500">
                      <FormattedMessage id={translations.fields.createdOn} />{' '}
                      <FormattedDate day="2-digit" month="2-digit" value={initialValues?.createdAt} year="numeric" />
                    </div>
                  )}
                </div>

                <div className="max-w-3xl space-y-6">
                  <ValidatedField
                    accept="image/png,image/jpeg"
                    as={ImageFileInput}
                    disabled={submitting}
                    field={InputWithLabel}
                    fieldClassName="inline-flex w-fit"
                    id="image"
                    label={<FormattedMessage id={translations.fields.logo} />}
                    name="image"
                  >
                    <div className="flex cursor-pointer items-center gap-4">
                      <Avatar alt="logo" size="xl" src={getSourceFromPossibleValue(values?.image)} />

                      <Button appearance="basic" as="button" className="pointer-events-none" type="button">
                        <FormattedMessage
                          id={values?.image ? translations.buttons.changeLogo : translations.buttons.addLogo}
                        />
                      </Button>
                    </div>
                  </ValidatedField>

                  <ValidatedField
                    field={InputWithLabel}
                    id="name"
                    label={<FormattedMessage id={translations.fields.benefitName} />}
                    name="name"
                    readOnly={submitting}
                  />

                  <ValidatedField
                    as={Select}
                    defaultValue={initialValues?.categoryId}
                    field={InputWithLabel}
                    id="categoryId"
                    items={categoriesItems || [{ name: '', value: '' }]}
                    label={<FormattedMessage id={translations.fields.category} />}
                    name="categoryId"
                    placeholder={intl.formatMessage({ id: translations.select.selectCategory })}
                    searchable
                  />

                  <MaxLengthIndicator max={800} name="description">
                    <ValidatedField
                      as={TextArea}
                      field={InputWithLabel}
                      fieldClassName="h-36 min-h-[6rem]"
                      help={<FormattedMessage id={translations.fields.benefitDescription.helper} />}
                      id="description"
                      label={<FormattedMessage id={translations.fields.benefitDescription.label} />}
                      name="description"
                      readOnly={submitting}
                    />
                  </MaxLengthIndicator>

                  <ValidatedField
                    field={InputWithLabel}
                    id="url"
                    label={<FormattedMessage id={translations.fields.link} />}
                    name="url"
                    placeholder={intl.formatMessage({ id: translations.placeholders.link })}
                    readOnly={submitting}
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center gap-6">
                <Button appearance="basic" className="w-48" onClick={() => form.restart()} type="button">
                  <FormattedMessage id={translations.buttons.cancel} />
                </Button>

                <Button appearance="primary" as={LoadingButton} className="w-48" loading={submitting} type="submit">
                  <FormattedMessage id={translations.buttons.save} />
                </Button>

                <SubmitFeedback {...{ submitError, submitSucceeded }} show={!dirtySinceLastSubmit && !submitting} />
              </div>
            </div>
          )}
        </Form>
      </LoadingOverlay>

      <Modal
        icon={deleteIcon}
        {...confirmationModal.props}
        buttonMessage={<FormattedMessage id={translations.buttons.deleteBenefit} />}
        description={<FormattedMessage id={translations.pages.benefits.deleteBenefitHelp} />}
        onConfirmation={async () =>
          await apiClient.benefit.deleteBenefit({ benefitId: benefitId! }).then(() => navigate('/benefits'))
        }
        title={<FormattedMessage id={translations.pages.benefits.deleteBenefit} />}
      />
    </Layout>
  );
};
