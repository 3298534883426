import { StudentDto } from '@billy/management-api-sdk/src/models/StudentDto';
import React from 'react';

type Props = {
  student: StudentDto;
};

export const StudentInformationsList: React.FC<Props> = ({ student }) => {
  return (
    <div className="mb-20 flex flex-col gap-14">
      <div className="flex flex-col gap-5">
        <p className="text-xl font-semibold">General</p>
        <ul className="grid grid-cols-2 gap-4">
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Full name</span>
            <p className="text-lg font-normal">{student.name}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Student code</span>
            <p className="text-lg font-normal">{student.studentCode}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Guardian</span>
            <p className="text-lg font-normal">{student?.motherName ? student.motherName : student?.fatherName}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Gaurdian code</span>
            <p className="text-lg font-normal">{student.parentCode}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Gender</span>
            <p className="text-lg font-normal">{student.gender}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Religion</span>
            <p className="text-lg font-normal">{student.religion}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Nationaly</span>
            <p className="text-lg font-normal">{student.nationality}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Birth date</span>
            <p className="text-lg font-normal">{student.birthDate}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Father name</span>
            <p className="text-lg font-normal">{student.fatherName}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Mother name</span>
            <p className="text-lg font-normal">{student.motherName}</p>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-5">
        <p className="text-xl font-semibold">School informations</p>
        <ul className="grid grid-cols-2 gap-4">
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Class</span>
            <p className="text-lg font-normal">{student.studentClass}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Division</span>
            <p className="text-lg font-normal">{student.division}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Joining date</span>
            <p className="text-lg font-normal">{student.joiningAcademicYear}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Current academic year</span>
            <p className="text-lg font-normal">{student.currentAcademicYear}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Fee collection start date</span>
            <p className="text-lg font-normal">{student.feeCollectionStartDate}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Joining class</span>
            <p className="text-lg font-normal">{student.studentClass}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Status</span>
            <p className="text-lg font-normal">{student.status}</p>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-5">
        <p className="text-xl font-semibold">Contact</p>
        <ul className="grid grid-cols-2 gap-4">
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Mother email</span>
            <p className="text-lg font-normal">{student.motherEmail}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Mother mobile</span>
            <p className="text-lg font-normal">{student.motherMobile}</p>
          </li>
          <li className="flex flex-col rounded-lg border bg-white p-2 shadow-md">
            <span className="text-md opacity-50">Address</span>
            <p className="text-lg font-normal">{student.address}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
