import React from 'react';

import { Appearance, Button } from './Button';

type Props = {
  buttonText?: React.ReactNode;
  appearance?: Appearance;
  svgPath?: string;
  shouldUseShadow?: boolean;
  alignRight?: boolean;
  dropdownClassName?: string;
  trigger?: React.ReactElement;
};

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  buttonText,
  appearance = 'primary',
  svgPath,
  shouldUseShadow = true,
  alignRight = false,
  dropdownClassName,
  trigger
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const className =
    dropdownClassName || `absolute mt-2 w-48 rounded-md bg-white shadow-lg z-10 ${alignRight ? 'right-0' : 'left-0'}`;

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      {trigger ? (
        React.cloneElement(trigger, { onClick: handleToggle })
      ) : (
        <Button appearance={appearance} onClick={handleToggle} shouldUseShadow={shouldUseShadow}>
          {svgPath && <img alt="" className={`h-5 w-5 ${buttonText ? 'mr-2 inline-block' : ''}`} src={svgPath} />}
          {buttonText}
        </Button>
      )}

      {isOpen && (
        <div className={className}>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              const childElement = child as React.ReactElement<{ className?: string; onClick?: () => void }>;

              if (child.type === 'div' && childElement.props.className?.includes('flex')) {
                return React.cloneElement(childElement, {
                  className: `w-full hover:bg-gray-100 cursor-pointer ${childElement.props.className || ''}`,
                  onClick: childElement.props.onClick
                });
              }
              return React.cloneElement(childElement, {
                className: `w-full hover:bg-gray-100 cursor-pointer ${childElement.props.className || ''}`
              });
            }
            return child;
          })}
        </div>
      )}
    </div>
  );
};
