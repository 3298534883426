/* eslint-disable react/jsx-sort-props */
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import * as yup from 'yup';

import { translations } from '@/locales';

import { Avatar } from '@/components/Avatar';
import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { Form } from '@/components/Form';
import { Checkbox } from '@/components/Form/Checkbox';
import { getSourceFromPossibleValue, ImageFileInput, ImageValue } from '@/components/Form/ImageFileInput';
import { Input } from '@/components/Form/Input';
import { InputWithLabel } from '@/components/Form/InputWithLabel';
import { TextArea } from '@/components/Form/TextArea';
import { ValidatedField } from '@/components/Form/ValidatedField';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingButton } from '@/components/LoadingButton';
import { Modal } from '@/components/Modal';
import { InvitationModal } from '@/components/Modal/InvitationModal';
import { Navbar } from '@/components/Navbar';
import { MemberProfile } from '@/components/Profile';
import { SubmitFeedback } from '@/components/SubmitFeedback';
import { Column, DataTable } from '@/components/Table/DataTable';
import { Status } from '@/constants';
import { useModal } from '@/hooks/useModal';
import { apiClient } from '@/services/api';
import { uploadFile } from '@/utils/functions';
import { CommunityManagerDto, CommunityPreviewDto } from '@billy/management-api-sdk';
import { PhotoIcon, PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

const maxSizeInMB = 2;
const minImageWidth = 2160;
const minImageHeight = 1024;

export const minLogoWidth = 512;
export const minLogoHeight = 512;

export const schema = yup
  .object({
    address: yup.string().required(),
    cardImage: yup
      .mixed<ImageValue | string>()
      .test({
        exclusive: true,
        message: translations.customValidation.maxFileSize,
        name: 'maxFileSize',
        params: { max: maxSizeInMB },
        test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
      })
      .test({
        exclusive: true,
        message: translations.customValidation.minImageResolution,
        name: 'minImageResolution',
        params: { height: minImageHeight, width: minImageWidth },
        test: (value) =>
          typeof value === 'string' ||
          !value?.file ||
          (value.image.width >= minImageWidth && value.image.height >= minImageHeight)
      })
      .required()
      .label(translations.fields.attachment),
    city: yup.string().required(),
    country: yup.string().required(),
    description: yup.string().optional().label(translations.fields.communityName),
    image: yup
      .mixed<ImageValue | string>()
      .test({
        exclusive: true,
        message: translations.customValidation.maxFileSize,
        name: 'maxFileSize',
        params: { max: maxSizeInMB },
        test: (value) => typeof value === 'string' || !value?.file || value.file.size <= maxSizeInMB * 1024 * 1024
      })
      .test({
        exclusive: true,
        message: translations.customValidation.minImageResolution,
        name: 'minImageResolution',
        params: { height: minLogoHeight, width: minLogoWidth },
        test: (value) =>
          typeof value === 'string' ||
          !value?.file ||
          (value.image.width >= minLogoWidth && value.image.height >= minLogoHeight)
      })
      .required()
      .label(translations.fields.attachment),
    name: yup.string().required().label(translations.fields.communityName),
    postalCode: yup.string().required(),
    state: yup.string().required(),
    type: yup.string().required('You must select either a Default or School type')
  })
  .required();

export const emailSchema = yup
  .object({
    email: yup.string().email().required().label(translations.fields.emailAddress)
  })
  .required();

export const CreateCommunityPage: React.FC = () => {
  const { communityId: loadedCommunityId } = useParams();

  const isNew = !loadedCommunityId;
  const { data: loadedCommunity } = useSWR(isNew ? null : ['get-community', loadedCommunityId], () =>
    apiClient.community.getCommunityDetails({ communityId: loadedCommunityId! })
  );

  const initialValues = React.useMemo(() => {
    return {
      address: loadedCommunity?.address?.address,
      cardImage: loadedCommunity?.membershipCardImage,
      city: loadedCommunity?.address?.city,
      country: loadedCommunity?.address?.country,
      description: loadedCommunity?.description,
      image: loadedCommunity?.image,
      managers: loadedCommunity?.managers,
      name: loadedCommunity?.name,
      postalCode: loadedCommunity?.address?.postalCode,
      state: loadedCommunity?.address?.state,
      type: loadedCommunity?.type
    };
  }, [loadedCommunity]);

  const [communityType, setSelectedType] = React.useState(initialValues.type || '');
  const [communityId, setCommunityId] = React.useState('');
  const [showAddManagers, setShowAddManagers] = React.useState(false);
  const [managers, setManagers] = React.useState<CommunityManagerDto[]>(initialValues.managers || []);
  const navigate = useNavigate();

  const handleManagerStatus = async (manager: CommunityManagerDto) => {
    manager.status === Status.ACTIVE
      ? await apiClient.community.suspendCommunityManagers({
          communityId,
          requestBody: { userIds: [manager.id] }
        })
      : await apiClient.community.addCommunityManagers({
          communityId,
          requestBody: { emails: [manager.email] }
        });

    const communityDetails = await apiClient.community.getCommunityDetails({ communityId });

    setManagers(communityDetails.managers);
  };

  const onInviteManager = async (email: string) => {
    await apiClient.community.addCommunityManagers({
      communityId,
      requestBody: { emails: [email] }
    });

    const communityDetails = await apiClient.community.getCommunityDetails({ communityId });

    setManagers(communityDetails.managers);
  };

  const logoUpload = async (image: string | ImageValue, communityId: string) => {
    if (typeof image === 'string') return image;
    else {
      const intent = await apiClient.community.initiateCommunityLogoUpload({
        communityId,
        requestBody: { mimeType: image.file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, image.file);

      return intent.asset;
    }
  };

  const membershipCardUpload = async (membershipCard: string | ImageValue, communityId: string) => {
    if (typeof membershipCard === 'string') return membershipCard;
    else {
      const intent = await apiClient.community.initiateCommunityMembershipCardUpload({
        communityId,
        requestBody: { mimeType: membershipCard.file.type }
      });

      await uploadFile(intent.upload.url, intent.upload.headers, membershipCard.file);

      return intent.asset;
    }
  };

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      const address = {
        address: values.address,
        city: values.city,
        country: values.country,
        postalCode: values.postalCode,
        state: values.state
      };

      if (isNew) {
        const community = await apiClient.community.createCommunity({
          requestBody: {
            address,
            description: values.description,
            name: values.name,
            type: communityType!
          }
        });
        await apiClient.community.updateCommunityDetails({
          communityId: community.id,
          requestBody: {
            address: {
              address: values.address,
              city: values.city,
              country: values.country,
              postalCode: values.postalCode,
              state: values.state
            },
            description: values.description,
            image: undefined,
            name: values.name,
            type: community.type
          }
        });

        setCommunityId(community.id);

        const image = await logoUpload(values.image, community!.id);
        const membership = await membershipCardUpload(values.cardImage, community!.id);

        await apiClient.community.updateCommunityDetails({
          communityId: community!.id,
          requestBody: { ...community, image, membership }
        });
      } else {
        const image = await logoUpload(values.image, loadedCommunityId);
        const membership = await membershipCardUpload(values.cardImage, loadedCommunityId);

        await apiClient.community.updateCommunityDetails({
          communityId: loadedCommunityId,
          requestBody: { ...values, address, image, membership, type: communityType! }
        });

        setCommunityId(loadedCommunityId);
      }
    },
    [communityType, isNew, loadedCommunityId]
  );

  const invitationModal = useModal(InvitationModal);

  const columns: Column<CommunityManagerDto>[] = [
    {
      accessor: 'name',
      cell: (manager: CommunityManagerDto) => (
        <MemberProfile
          email={manager.email}
          firstName={manager.firstName}
          lastName={manager.lastName}
          src={manager.image}
        />
      ),
      header: 'Name'
    },
    {
      accessor: 'dateAdded',
      cell: (manager: CommunityManagerDto) => (
        <p className="font-semibold">
          <FormattedDate day="2-digit" month="2-digit" value={manager.createdAt} year="numeric" />
        </p>
      ),
      header: 'Date added'
    },
    {
      accessor: 'status',
      cell: (manager: CommunityManagerDto) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <p
            className={classNames(
              'w-max rounded-full bg-opacity-10 px-4 py-1 font-semibold',
              manager.status == 'active' ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#79747E] text-[#79747E]'
            )}
          >
            {manager.status}
          </p>
        </div>
      ),
      filter: {
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'actions',
      cell: (manager: CommunityManagerDto) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <Button
            className={classNames(
              'w-full rounded-xl bg-opacity-10 px-8 py-2 font-bold hover:bg-opacity-30',
              manager.status !== Status.ACTIVE ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#DE3530] text-[#DE3530]'
            )}
            onClick={async () => handleManagerStatus(manager)}
            type="button"
          >
            {manager.status == Status.ACTIVE ? 'Suspend' : 'Reactivate'}
          </Button>
        </div>
      ),
      header: ''
    }
  ];

  return (
    <Layout>
      <div className="my-5 flex w-full justify-between">
        <div className="flex items-center">
          <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
            <FormattedMessage id={translations.pages.community.communities} />
          </Link>

          <ChevronRightIcon className="w-5 text-gray-400" />

          <Link as={RouterLink} className="text-sm font-medium text-[#ED6F4C]" to="#">
            <FormattedMessage id={translations.pages.community.newCommunity} />
          </Link>
        </div>
        <div>
          <Navbar title="" />
        </div>
      </div>
      <div className="flex w-full flex-col">
        <div className="mx-auto mb-10 flex w-full max-w-2xl text-2xl font-semibold">
          <FormattedMessage id={translations.pages.community.newCommunity} />
        </div>

        <div className="flex w-full items-center">
          <Form className="flex w-full grow flex-col items-center" {...{ initialValues, onSubmit, schema }}>
            {({ submitting, values, form, submitError, submitSucceeded, dirtySinceLastSubmit }) => {
              return (
                <>
                  <div className="mx-auto flex w-full max-w-2xl flex-col justify-between">
                    <div className="mb-10 flex flex-col gap-5">
                      <div className="flex flex-col gap-3">
                        <p className="text-lg font-medium text-gray-900">
                          <FormattedMessage id={translations.pages.community.communityType} />
                        </p>
                        <p className="text-lg text-gray-900">
                          <FormattedMessage id={translations.pages.community.orisonWarning} />
                        </p>
                      </div>

                      <div className="flex items-center gap-5">
                        <label
                          className={classNames(
                            'cursor-pointer rounded-xl border-2 px-4 py-2',
                            communityType == CommunityPreviewDto.type.DEFAULT ? 'border-indigo-400' : '',
                            showAddManagers ? 'cursor-default' : ''
                          )}
                        >
                          <ValidatedField
                            disabled={showAddManagers}
                            checked={communityType == CommunityPreviewDto.type.DEFAULT}
                            field={Checkbox}
                            fieldClassName={'text-lg'}
                            id="default"
                            label="Default"
                            name="type"
                            onChange={() => setSelectedType(CommunityPreviewDto.type.DEFAULT)}
                            readOnly={submitting}
                            type="checkbox"
                            value="default"
                          />
                        </label>
                        <label
                          className={classNames(
                            'cursor-pointer rounded-xl border-2 px-4 py-2',
                            communityType == CommunityPreviewDto.type.SCHOOL ? 'border-indigo-400' : '',
                            showAddManagers ? 'cursor-default' : ''
                          )}
                        >
                          <ValidatedField
                            disabled={showAddManagers}
                            checked={communityType == CommunityPreviewDto.type.SCHOOL}
                            field={Checkbox}
                            fieldClassName={'text-lg'}
                            id="school"
                            label="School"
                            name="type"
                            onChange={() => setSelectedType(CommunityPreviewDto.type.SCHOOL)}
                            readOnly={submitting}
                            type="checkbox"
                            value="school"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="space-y-6">
                      <div className="text-lg font-medium text-gray-900">
                        <FormattedMessage id={translations.pages.pageSections.generalInformation} />
                      </div>

                      <ValidatedField
                        accept="image/png,image/jpeg"
                        as={ImageFileInput}
                        disabled={submitting || showAddManagers}
                        field={InputWithLabel}
                        fieldClassName="flex flex-col gap-2"
                        id="image"
                        label={'Community logo'}
                        name="image"
                      >
                        <div
                          className={classNames(
                            'flex cursor-pointer items-center gap-4',
                            showAddManagers ? 'cursor-default' : ''
                          )}
                        >
                          <Avatar
                            alt="logo"
                            className="border-none"
                            size="2xl"
                            src={getSourceFromPossibleValue(values?.image)}
                          />

                          <Button
                            appearance="basicSecondary"
                            as="button"
                            className="pointer-events-none px-10 hover:bg-gray-200"
                            type="button"
                          >
                            {values?.image ? 'Change' : 'Upload'}
                          </Button>
                        </div>
                      </ValidatedField>

                      <ValidatedField
                        disabled={showAddManagers}
                        field={Input}
                        fieldClassName={'h-14'}
                        id="name"
                        name="name"
                        placeholder="Community name"
                        readOnly={submitting}
                      />

                      <ValidatedField
                        disabled={showAddManagers}
                        as={TextArea}
                        field={Input}
                        fieldClassName="h-36 min-h-[6rem] mb-5"
                        id="description"
                        name="description"
                        placeholder={'About the community'}
                        readOnly={!!submitting}
                      />

                      <ValidatedField
                        accept="image/png,image/jpeg"
                        as={ImageFileInput}
                        disabled={submitting || showAddManagers}
                        field={InputWithLabel}
                        fieldClassName="flex flex-col gap-2"
                        id="cardImage"
                        label="Background Image"
                        name="cardImage"
                      >
                        {values.cardImage ? (
                          <div className={classNames('h-60 cursor-pointer', showAddManagers ? 'cursor-default' : '')}>
                            <img
                              className={'h-full object-cover'}
                              src={getSourceFromPossibleValue(values?.cardImage)}
                            />
                          </div>
                        ) : (
                          <div
                            className={classNames(
                              'flex h-60 cursor-pointer flex-col items-center justify-center gap-1 rounded border-2 border-dashed border-gray-300',
                              showAddManagers ? 'cursor-default' : ''
                            )}
                          >
                            <PhotoIcon className="w-12 text-gray-300" />
                            <p className="text-[#ED6F4C]">Upload image</p>
                            <p className="text-gray-300"> PNG or JPG images of 2160 x 1080px, up to 2MB</p>
                          </div>
                        )}
                      </ValidatedField>
                    </div>

                    <div className="mt-10 space-y-6">
                      <div className="text-lg font-medium text-gray-900">
                        <FormattedMessage id={translations.fields.location} />
                      </div>

                      <ValidatedField
                        disabled={showAddManagers}
                        field={Input}
                        fieldClassName={'h-14'}
                        id="country"
                        name="country"
                        placeholder="Country"
                        readOnly={submitting}
                      />
                      <ValidatedField
                        disabled={showAddManagers}
                        field={Input}
                        fieldClassName={'h-14'}
                        id="state"
                        name="state"
                        placeholder="State/province/region"
                        readOnly={submitting}
                      />
                      <ValidatedField
                        disabled={showAddManagers}
                        field={Input}
                        fieldClassName={'h-14'}
                        id="city"
                        name="city"
                        placeholder="City"
                        readOnly={submitting}
                      />
                      <ValidatedField
                        disabled={showAddManagers}
                        field={Input}
                        fieldClassName={'h-14'}
                        id="address"
                        name="address"
                        placeholder="Address"
                        readOnly={submitting}
                      />
                      <ValidatedField
                        disabled={showAddManagers}
                        field={Input}
                        fieldClassName={'h-14'}
                        id="postalCode"
                        name="postalCode"
                        placeholder="ZIP/Postal Code"
                        readOnly={submitting}
                      />
                    </div>

                    <hr className="my-10" />
                  </div>

                  {showAddManagers && (
                    <div className="my-5 flex w-full max-w-7xl flex-col gap-8">
                      <div className="flex w-full items-center justify-between">
                        <p className="text-xl font-semibold">Managers</p>
                        <Button
                          appearance="primary"
                          className="flex w-48 items-center justify-center gap-2"
                          onClick={() => {
                            values.name
                              ? invitationModal
                                  .open()
                                  .then()
                                  .catch(() => null)
                              : null;
                          }}
                          type="button"
                        >
                          <PlusIcon className="h-5 w-5" />
                          <p>Add manager</p>
                        </Button>
                      </div>
                      {managers.length <= 0 ? (
                        <EmptyList
                          description={<FormattedMessage id={translations.emptyStates.addManager} />}
                          title={<FormattedMessage id={translations.emptyStates.noManagers} />}
                        >
                          <Button
                            appearance="primary"
                            className="flex w-48 items-center justify-center gap-2"
                            onClick={() => {
                              values.name
                                ? invitationModal
                                    .open()
                                    .then()
                                    .catch(() => null)
                                : null;
                            }}
                            type="button"
                          >
                            <PlusIcon className="h-5 w-5" />
                            <p>Add manager</p>
                          </Button>
                        </EmptyList>
                      ) : (
                        <DataTable columns={columns} data={managers} />
                      )}
                    </div>
                  )}

                  {showAddManagers ? (
                    <Button
                      appearance="primary"
                      as={LoadingButton}
                      className="mt-6 w-60"
                      loading={submitting}
                      onClick={() => navigate(`/communities`)}
                      type="button"
                    >
                      Finish
                    </Button>
                  ) : isNew ? (
                    <div className="mx-auto my-10 flex w-full max-w-2xl justify-between gap-6">
                      <Button
                        appearance="basicSecondary"
                        className="mt-6 w-full hover:bg-gray-200 disabled:opacity-50"
                        onClick={() => {
                          form.restart();
                        }}
                        type="button"
                      >
                        <FormattedMessage id={translations.buttons.cancel} />
                      </Button>

                      <Button
                        appearance="primary"
                        as={LoadingButton}
                        className="mt-6 w-full"
                        loading={submitting}
                        onClick={() => {
                          form.submit()?.then(() => navigate(`/communities`));
                        }}
                        type="button"
                      >
                        Create community
                      </Button>

                      <Button
                        appearance="primary"
                        as={LoadingButton}
                        className="mt-6 w-full"
                        loading={submitting}
                        onClick={() => {
                          form.submit()?.then(() => setShowAddManagers(true));
                        }}
                        type="button"
                      >
                        Create + Add managers
                      </Button>
                    </div>
                  ) : (
                    <div className="mx-auto my-10 flex w-full max-w-2xl justify-between gap-6">
                      <Button
                        appearance="basicSecondary"
                        className="mt-6 w-full hover:bg-gray-200 disabled:opacity-50"
                        onClick={() => navigate(`/communities/${loadedCommunityId}`)}
                        type="button"
                      >
                        <FormattedMessage id={translations.buttons.cancel} />
                      </Button>

                      <Button
                        appearance="primary"
                        as={LoadingButton}
                        className="mt-6 w-full"
                        loading={submitting}
                        onClick={() => {
                          form.submit()?.then(() => navigate(`/communities/${loadedCommunityId}`));
                        }}
                        type="button"
                      >
                        Edit community
                      </Button>
                    </div>
                  )}

                  <SubmitFeedback {...{ submitError, submitSucceeded }} show={!submitting && !dirtySinceLastSubmit} />
                  <Modal
                    onInvite={async (email) => onInviteManager(email)}
                    schema={emailSchema}
                    {...invitationModal.props}
                    description={<FormattedMessage id={translations.pages.community.inviteManager} />}
                    successMessage={<FormattedMessage id={translations.pages.community.invitedManager} />}
                    title={<FormattedMessage id={translations.buttons.addManager} />}
                  />
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </Layout>
  );
};
