import {
  CalendarIcon,
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  PaintBrushIcon,
  TagIcon,
  TicketIcon,
  UserGroupIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { translations } from '@/locales';

import { useRole } from '@/hooks/useRole';

import LogoName from '@/assets/sidebar-LogoName.png';
import standardLogo from '@/assets/univerz-app-icon.png';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Link } from './Link';

interface NavigationItem {
  name: React.ReactNode;
  to: string;
  icon: React.ReactNode;
  onlyAdmin: boolean;
}

const getNavigationItems = (isAdmin: boolean, community?: { id: string; type: string }): NavigationItem[] => {
  const navigation: NavigationItem[] = [];

  navigation.push({
    icon: <ChartBarIcon className="h-6 w-6 stroke-2" />,
    name: 'dashboard',
    onlyAdmin: true,
    to: '/dashboard'
  });

  navigation.push({
    icon: <UserGroupIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.community.communities} />,
    onlyAdmin: true,
    to: '/communities'
  });

  navigation.push(
    community?.type === 'school'
      ? {
          icon: <UsersIcon className="h-6 w-6 stroke-2" />,
          name: 'Students',
          onlyAdmin: false,
          to: isAdmin ? `/communities/${community?.id}/students` : '/students'
        }
      : {
          icon: <UsersIcon className="h-6 w-6 stroke-2" />,
          name: <FormattedMessage id={translations.pages.members.members} />,
          onlyAdmin: false,
          to: '/members'
        }
  );

  navigation.push({
    icon: <ChatBubbleLeftIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.messages.messages} />,
    onlyAdmin: true,
    to: '/messages'
  });

  navigation.push({
    icon: <ChatBubbleLeftEllipsisIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.messages.inbox} />,
    onlyAdmin: false,
    to: '/inbox'
  });

  navigation.push({
    icon: <CalendarIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: 'Events',
    onlyAdmin: false,
    to: '/events'
  });

  navigation.push({
    icon: <ChatBubbleLeftEllipsisIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.messages.messages} />,
    onlyAdmin: false,
    to: '/messages'
  });

  navigation.push({
    icon: <TicketIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.benefits.benefits} />,
    onlyAdmin: false,
    to: '/benefits'
  });

  navigation.push({
    icon: <ClipboardDocumentListIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.surveys.surveys} />,
    onlyAdmin: true,
    to: '/surveys'
  });

  navigation.push({
    icon: <PaintBrushIcon className="h-6 w-6 stroke-2" />,
    name: 'Onboarding arts',
    onlyAdmin: true,
    to: '/onboardingArts'
  });

  navigation.push({
    icon: <DocumentTextIcon className="h-6 w-6 stroke-2" />,
    name: 'Specialist posts',
    onlyAdmin: true,
    to: '/specialistPosts'
  });

  navigation.push({
    icon: <TagIcon className="h-6 w-6 stroke-2" />,
    name: 'Pannel users and tags',
    onlyAdmin: true,
    to: '/pannelUsersAndTags'
  });

  return isAdmin ? navigation : navigation.filter(({ onlyAdmin }) => !onlyAdmin);
};

const navigation: NavigationItem[] = [
  {
    icon: <ChartBarIcon className="h-6 w-6 stroke-2" />,
    name: 'dashboard',
    onlyAdmin: true,
    to: '/dashboard'
  },
  {
    icon: <UserGroupIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.community.communities} />,
    onlyAdmin: true,
    to: '/communities'
  },
  {
    icon: <UsersIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.members.members} />,
    onlyAdmin: false,
    to: '/members'
  },
  {
    icon: <ChatBubbleLeftIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.messages.messages} />,
    onlyAdmin: false,
    to: '/messages'
  },
  {
    icon: <ChatBubbleLeftEllipsisIcon className="h-6 w-6 stroke-2 text-gray-400" />,
    name: <FormattedMessage id={translations.pages.messages.inbox} />,
    onlyAdmin: false,
    to: '/inbox'
  },
  {
    icon: <TicketIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.benefits.benefits} />,
    onlyAdmin: false,
    to: '/benefits'
  },
  {
    icon: <ClipboardDocumentListIcon className="h-6 w-6 stroke-2" />,
    name: <FormattedMessage id={translations.pages.surveys.surveys} />,
    onlyAdmin: true,
    to: '/surveys'
  },
  {
    icon: <PaintBrushIcon className="h-6 w-6 stroke-2" />,
    name: 'Onboarding arts',
    onlyAdmin: true,
    to: '/onboardingArts'
  },
  {
    icon: <DocumentTextIcon className="h-6 w-6 stroke-2" />,
    name: 'Specialist posts',
    onlyAdmin: true,
    to: '/specialistPosts'
  },
  {
    icon: <TagIcon className="h-6 w-6 stroke-2" />,
    name: 'Pannel users and tags',
    onlyAdmin: true,
    to: '/pannelUsersAndTags'
  }
];

const onlyAdmin = navigation.filter((item) => item.onlyAdmin).map((item) => item.to);

export const Sidebar: React.FC = () => {
  const { isAdmin, community, communities } = useRole();
  const [showcommunities, setShowcommunities] = React.useState(false);
  const navigate = useNavigate();

  const Icon = showcommunities ? ChevronUpIcon : ChevronDownIcon;
  const location = useLocation();
  const currentUrl = location.pathname;

  const toggleShowcommunities = React.useCallback(() => {
    setShowcommunities(!showcommunities);
  }, [showcommunities]);

  const handleChangeCommunity = async (_community: any) => {
    localStorage.setItem('communityId', _community.id);
    currentUrl.includes(`/communities/${community!.id}`) && navigate(`/communities/${_community?.id}`);
    setShowcommunities(false);
  };

  const navigationItems = React.useMemo(() => getNavigationItems(isAdmin, community), [isAdmin, community]);

  return (
    <div className="fixed top-0 left-0 flex h-full w-80 flex-col justify-between border-r border-gray-200">
      <div>
        {!isAdmin ? (
          <div className="flex w-full flex-col items-center">
            <div className="my-5 h-20 w-20">
              <img
                alt="Community logo"
                className="h-full w-full rounded-full object-cover"
                src={community?.image || standardLogo}
              />
            </div>

            <div className="relative">
              <div className="flex cursor-pointer items-center gap-1" onClick={toggleShowcommunities}>
                <p className="text-ls font-semibold">{community?.name}</p>
                <Icon className="h-4 w-4 " />
              </div>

              {showcommunities && (
                <div className="absolute top-10 left-0 z-10 rounded-lg border bg-white">
                  {communities?.map((_community) => (
                    <>
                      <div
                        className="text-ls flex cursor-pointer items-center justify-between gap-5 whitespace-nowrap p-2 duration-300 hover:bg-gray-100"
                        key={_community.id}
                        onClick={() => handleChangeCommunity(_community)}
                      >
                        <div className="flex items-center gap-2">
                          <div className="h-10 w-10">
                            <img
                              alt="Community logo"
                              className="h-full w-full rounded-full object-cover"
                              src={_community?.image || standardLogo}
                            />
                          </div>
                          <span>{_community.name}</span>
                        </div>
                        <ChevronRightIcon className="h-4 w-4 text-[#FEA68A]" />
                      </div>
                      <hr key={'hr-' + _community.id} />
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="mt-10 flex h-12 w-full justify-center">
            <img alt="Univerz logo" className="object-cover" src={LogoName} />
          </div>
        )}

        <nav className="mt-20 pl-2">
          {navigationItems.map((item) => (
            <Link
              as={RouterLink}
              className={classnames(
                !isAdmin && onlyAdmin.includes(item.to) && 'hidden',
                isAdmin && ['/benefits', '/inbox'].includes(item.to) && 'hidden',
                currentUrl.includes(item.to)
                  ? 'border-r-4 border-[#ED6F4C] text-[#FEA68A]'
                  : 'text-gray-900 hover:bg-gray-100',
                'my-1 flex items-center px-2 py-2 text-sm font-medium'
              )}
              key={item.to}
              to={item.to}
            >
              <span
                className={classnames(
                  currentUrl.includes(item.to)
                    ? 'bg-[#FEA68A] bg-opacity-10 text-[#FEA68A]'
                    : 'bg-gray-100 text-gray-400',
                  'mr-3 rounded-full p-2'
                )}
              >
                {item.icon}
              </span>

              <span>{item.name}</span>
            </Link>
          ))}

          {!isAdmin && (
            <Link
              as={RouterLink}
              className={classnames(
                currentUrl.includes('/communities/')
                  ? 'border-r-4 border-[#ED6F4C] text-[#FEA68A]'
                  : 'text-gray-900 hover:bg-gray-100',
                'text-gray-900 hover:bg-gray-100',
                'my-1 flex items-center px-2 py-2 text-sm font-medium'
              )}
              to={`/communities/${community?.id}`}
            >
              <div className="mr-3 h-10 w-10">
                <img
                  alt="Community logo"
                  className="h-full w-full rounded-full object-cover"
                  src={community?.image || standardLogo}
                />
              </div>
              <span>My community</span>
            </Link>
          )}
        </nav>
      </div>

      {!isAdmin && (
        <div className="mb-10 flex h-8 w-full justify-center">
          <img alt="Univerz logo" className="h-full object-contain" src={LogoName} />
        </div>
      )}
    </div>
  );
};
