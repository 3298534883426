import { CommunityPreviewDto } from '@billy/management-api-sdk';
import React, { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { translations } from '@/locales';

import { Button } from '@/components/Button';
import { EmptyList } from '@/components/EmptyList';
import { apiClient } from '@/services/api';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Column, DataTable } from './DataTable';

import { Link } from '@/components/Link';

import communityImageDefault from '@/assets/community-bg-logo.png';
import { useModal } from '@/hooks/useModal';
import { AsyncButton } from '../AsyncButton';
import { Modal } from '../Modal';
import { SelectSchoolModal } from '../Modal/SelectSchoolModal';

export type Community = {
  id: string;
  name: string;
  createdAt: Date;
  active: boolean;
};

type Props = {
  communities?: CommunityPreviewDto[];
  className?: string;
  empty?: boolean;
  noResults?: boolean;
  onReload(): void;
  onFilter?(field: string, values: (string | number)[]): void;
};

export const CommunitiesList: React.FC<Props> = ({ communities, empty, onFilter, onReload }) => {
  const navigate = useNavigate();
  const [selectedCommunity, setSelectedCommunity] = useState<CommunityPreviewDto | null>(null);
  const importModal = useModal(SelectSchoolModal);

  const handleFilter = (field: string, values: (string | number)[]) => {
    onFilter?.(field, values);
  };

  const onImportFromTemplate = () => {
    if (!selectedCommunity) return;
    localStorage.setItem('communityId', selectedCommunity.id);
    navigate('/import-students');
  };

  const handleCommunityStatus = async (community: CommunityPreviewDto) => {
    community.status === 'active'
      ? await apiClient.community.suspendCommunity({ communityId: community.id }).then(() => onReload())
      : await apiClient.community.reactivateCommunity({ communityId: community.id }).then(() => onReload());
  };

  const columns: Column<CommunityPreviewDto>[] = [
    {
      accessor: 'name',
      cell: (community: CommunityPreviewDto) => (
        <div className={`flex items-center whitespace-nowrap py-4 text-sm text-gray-900 `}>
          <div className="h-10 w-10">
            <img
              alt="Benefit logo"
              className="h-full w-full rounded-full object-cover"
              src={community.image ? community.image : communityImageDefault}
            />
          </div>
          <div className="ml-2">
            <p className="font-semibold">{community.name}</p>
          </div>
        </div>
      ),
      header: 'Name'
    },
    {
      accessor: 'type',
      cell: (community: CommunityPreviewDto) => (
        <p className="font-semibold">{community.type == 'default' ? 'Default' : 'School'}</p>
      ),
      filter: {
        options: [
          { label: 'Default', value: 'default' },
          { label: 'School', value: 'school' }
        ],
        type: 'multiple'
      },
      header: 'Type'
    },
    {
      accessor: 'dateAdded',
      cell: (community: CommunityPreviewDto) => (
        <p className="font-semibold">
          <FormattedDate day="2-digit" month="2-digit" value={community.createdAt} year="numeric" />
        </p>
      ),
      header: 'Date added'
    },
    {
      accessor: 'status',
      cell: (community: CommunityPreviewDto) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <p
            className={classNames(
              'w-max rounded-full bg-opacity-10 px-4 py-1 font-semibold',
              community.status === 'active'
                ? 'bg-[#52B642] text-[#52B642]'
                : community.status === 'pending'
                ? 'bg-[#F1A660] text-[#F1A660]'
                : 'bg-[#79747E] text-[#79747E]'
            )}
          >
            {community.status}
          </p>
        </div>
      ),
      filter: {
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
          { label: 'Pending', value: 'pending' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'actions',
      cell: (community: CommunityPreviewDto) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <Button
            className={classNames(
              'w-full rounded-xl bg-opacity-10 px-8 py-2 font-bold hover:bg-opacity-30',
              community.status == 'inactive' ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#DE3530] text-[#DE3530]'
            )}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleCommunityStatus(community);
            }}
          >
            {community.status !== 'inactive' ? 'Suspend' : 'Reactivate'}
          </Button>
        </div>
      ),
      header: ''
    },
    {
      accessor: 'actions',
      cell: (community: CommunityPreviewDto) => {
        if (community.type !== 'school' || community.status !== 'active') return null;

        return (
          <AsyncButton
            appearance="primary"
            className="w-full rounded-xl disabled:opacity-50"
            onClick={async (e: React.MouseEvent) => {
              e.stopPropagation();

              if (community.hasStudents || community.schoolCode) {
                return apiClient.school.importGuardians({ communityId: community.id });
              }

              setSelectedCommunity(community);
              importModal.open();
            }}
            type="button"
          >
            {community.hasStudents || community.schoolCode ? 'Invite' : 'Import'}
          </AsyncButton>
        );
      },
      header: ''
    },
    {
      accessor: 'actions',
      cell: (community: CommunityPreviewDto) => (
        <Link as={RouterLink} className="cursor-pointer font-semibold" to={`/communities/${community.id}`}>
          <ChevronRightIcon className="w-4 text-sm text-[#ED6F4C]" />
        </Link>
      ),
      header: ''
    }
  ];

  return (
    <>
      {empty ? (
        <EmptyList
          description={<FormattedMessage id={translations.emptyStates.createCommunity} />}
          title={<FormattedMessage id={translations.emptyStates.noCommunities} />}
        >
          <Button appearance="primary" as={RouterLink} className="flex w-48 justify-center" to="/communities/new">
            <FormattedMessage id={translations.buttons.createCommunity} />
          </Button>
        </EmptyList>
      ) : (
        <DataTable
          columns={columns}
          data={communities || []}
          onFilter={handleFilter}
          onRowClick={(community) => {
            localStorage.setItem('communityId', community.id);
            navigate(`/communities/${community.id}`);
          }}
        />
      )}

      {selectedCommunity && (
        <Modal
          {...importModal.props}
          communityId={selectedCommunity.id}
          onClose={() => {
            onReload();
            setSelectedCommunity(null);
          }}
          onImportFromTemplate={onImportFromTemplate}
        />
      )}
    </>
  );
};
