import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { Navbar } from '@/components/Navbar';
import { Column, DataTable } from '@/components/Table/DataTable';
import { page, search, size, status, statuses, types, useQueryParams } from '@/hooks/useQueryParams';
import { translations } from '@/locales';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import deletePath from '@/assets/images/delete.svg';
import edit from '@/assets/images/edit.svg';
import verticalDotsPath from '@/assets/images/vertical-dots.svg';

import femaleGenderIcon from '@/assets/female-gender-icon.png';
import maleGenderIcon from '@/assets/male-gender-icon.png';
import { Button } from '@/components/Button';
import { Dropdown } from '@/components/Dropdown';
import { Modal } from '@/components/Modal';
import { DeleteBasicModal } from '@/components/Modal/DeleteBasicModal';
import { Pagination } from '@/components/Pagination';
import { useModal } from '@/hooks/useModal';
import { useRole } from '@/hooks/useRole';
import { apiClient } from '@/services/api';
import { StudentPreviewDto } from '@billy/management-api-sdk';
import useSWR from 'swr';

const config = { page, search, size, status, statuses, types };

export const StudentsListPage = () => {
  const [queryParams, updateQueryParams] = useQueryParams({ config });
  const [selectedStudent, setSelectedStudent] = React.useState<StudentPreviewDto>();
  const { communityId } = useParams();
  const { community: communityRole, communityId: communityIdRole } = useRole();
  const navigate = useNavigate();

  const confirmDeleteStudentsModal = useModal(DeleteBasicModal);

  const studentsKey = `schools/get-students?page=${queryParams.page}&size=${queryParams.size}&statuses=${queryParams.statuses}&types=${queryParams.types}`;

  const internalStudentsKey = `students/find?page=${queryParams.page}&size=${queryParams.size}`;

  const { data: community } = useSWR(
    [communityId, communityRole],
    () => apiClient.community.getCommunityDetails({ communityId: communityId ?? communityIdRole ?? '' }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const {
    data: studentsData,
    isLoading: studentsLoading,
    error: studentsError
  } = useSWR(
    [studentsKey, internalStudentsKey, queryParams, communityIdRole],
    () => {
      return apiClient.school.findStudents({
        communityId: communityId ?? communityIdRole ?? '',
        pageNumber: queryParams.page,
        pageSize: queryParams.size,
        searchQuery: queryParams.search
      });
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const columns: Column<StudentPreviewDto>[] = [
    {
      accessor: 'name',
      cell: (student) => <p>{student.name}</p>,
      header: 'Name'
    },
    {
      accessor: 'guardian',
      cell: (student) => <p className="font-semibold">{student.guardianName}</p>,

      header: 'guardian'
    },
    {
      accessor: 'code',
      cell: (student) => <p className="font-semibold">{student.studentCode}</p>,
      header: 'Code'
    },
    {
      accessor: 'class',
      cell: (student) => <p className="font-semibold">{student.studentClass}</p>,
      filter: {
        options: [],
        type: 'multiple'
      },
      header: 'Class'
    },
    {
      accessor: 'division',
      cell: (student) => <p className="font-semibold">{student.division}</p>,
      filter: {
        options: [],
        type: 'multiple'
      },
      header: 'Division'
    },
    {
      accessor: 'gender',
      cell: (student) => {
        const studentIsFemale = student.gender.trim().toLocaleLowerCase() === 'female';

        return (
          <div className={`whitespace-nowrap py-4 text-sm`}>
            <div
              className={classNames(
                'flex w-max items-center gap-1 rounded-full bg-opacity-10 px-4 py-1 font-semibold',
                studentIsFemale ? 'bg-[#C34E68] text-[#C34E68]' : 'bg-[#257C9C] text-[#257C9C]'
              )}
            >
              <div>
                <img alt="gender Icon" src={studentIsFemale ? femaleGenderIcon : maleGenderIcon} />
              </div>
              <p>{studentIsFemale ? 'Female' : 'Male'}</p>
            </div>
          </div>
        );
      },
      filter: {
        options: [
          { label: 'Male', value: 'male' },
          { label: 'Female', value: 'female' }
        ],
        type: 'multiple'
      },
      header: 'Gender'
    },
    {
      accessor: 'status',
      cell: (student) => {
        const studentIsActive = student.status.trim().toLocaleLowerCase() === 'active';

        return (
          <div className={`whitespace-nowrap py-4 text-sm`}>
            <p
              className={classNames(
                'w-max rounded-full bg-opacity-10 px-4 py-1 font-semibold',
                studentIsActive ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#79747E] text-[#79747E]'
              )}
            >
              {studentIsActive ? 'Active' : 'Inactive'}
            </p>
          </div>
        );
      },
      filter: {
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
          { label: 'Pending', value: 'pending' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'actions',
      cell: (student) =>
        community?.isInternalSchool && (
          <Dropdown
            appearance="filter"
            dropdownClassName="absolute mt-2 w-48 rounded-md bg-white shadow-lg z-10 border border-gray-200 right-0 top-full"
            shouldUseShadow={false}
            svgPath={verticalDotsPath}
            trigger={
              <Button appearance="filter" className="rounded-full p-2 " shouldUseShadow={false}>
                <img alt="vertical dots" className="h-5 w-5" src={verticalDotsPath} />
              </Button>
            }
          >
            <Button
              className="flex w-full items-center gap-2 px-4 py-2 text-sm hover:bg-gray-200"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(`/students/edit/${student.studentCode}`);
              }}
            >
              <div className="flex items-center gap-2">
                <img alt="edit" className="h-7 w-7" src={edit} />
                <FormattedMessage id={translations.buttons.edit} />
              </div>
            </Button>

            <Button
              className="flex w-full items-center gap-2 px-4 py-2 text-sm hover:bg-gray-200"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedStudent(student);
                confirmDeleteStudentsModal.open().catch(() => null);
              }}
            >
              <div className="flex items-center gap-2">
                <img alt="delete" className="h-7 w-7" src={deletePath} />
                <FormattedMessage id={translations.buttons.delete} />
              </div>
            </Button>
          </Dropdown>
        ),
      header: ''
    }
  ];

  return (
    <>
      <Layout>
        <div className="mb-6 flex gap-5">
          <div className="mt-5 flex w-full justify-between">
            <div className="flex items-center">
              <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
                <FormattedMessage id={translations.pages.community.communities} />
              </Link>

              <ChevronRightIcon className="w-5 text-gray-400" />

              <Link as={RouterLink} className="text-sm font-medium text-gray-500" to={`/communities/${communityId}`}>
                {community?.name}
              </Link>

              <ChevronRightIcon className="w-5 text-gray-400" />

              <Link as={RouterLink} className="text-sm font-medium text-[#ED6F4C]" to="#">
                Students
              </Link>
            </div>
            <div>
              <Navbar title="" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-semibold">Students ({studentsData?.page.total})</h2>
            {studentsData && studentsData.items.length > 0 && (
              <DebouncedInput
                as={SearchInput}
                className="w-[22.5rem]"
                initialValue={queryParams.search}
                onChange={(search: string) => updateQueryParams({ page: 1, search })}
                placeholder={'Search by name, guardian, code, class or division'}
              />
            )}
          </div>
          {community?.isInternalSchool && (
            <Button
              appearance="primary"
              className="h-10 w-40 rounded-xl disabled:opacity-50"
              onClick={() => navigate('/students/new')}
              type="button"
            >
              New student
            </Button>
          )}
        </div>
        <LoadingOverlay {...{ error: studentsError, loading: studentsLoading }} displayLoader>
          {studentsData && studentsData.items.length > 0 ? (
            <>
              <DataTable
                columns={columns}
                data={studentsData?.items ?? []}
                onRowClick={(student) => navigate(`/students/${student.studentCode}`)}
              />
              {!!studentsData && (
                <Pagination
                  className="mt-9"
                  currentPage={studentsData.page.pageNumber}
                  onPageChange={(page: number) => updateQueryParams({ page })}
                  pageSize={studentsData.page.pageSize}
                  totalItems={studentsData.page.total}
                />
              )}
            </>
          ) : (
            <div className="flex h-80 items-center">
              <p className="text-center">
                There are no guardians to show yet.{' '}
                <strong>Ask a UniverZ admin to send the invite for guardians</strong> or wait for them to accept the
                community invitation.
              </p>
            </div>
          )}
        </LoadingOverlay>

        <Modal
          buttonMessage={'Delete'}
          onDeletion={async () => await apiClient.student.deleteStudent({ studentCode: selectedStudent!.studentCode })}
          subhead="If you delete the student from the school you will not be able to reverse it."
          title="Delete student from school"
          {...confirmDeleteStudentsModal.props}
        />
      </Layout>
    </>
  );
};
