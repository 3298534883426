import React from 'react';
import useSWR from 'swr';

import { apiClient } from '@/services/api';

import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { Navbar } from '@/components/Navbar';
import { StudentInformationsList } from '@/components/Table/StudentInformationsList';
import { StudentInvoicesList } from '@/components/Table/StudentInvoicesList';
import { useRole } from '@/hooks/useRole';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link as RouterLink, useParams } from 'react-router-dom';

import femaleGenderIcon from '@/assets/female-gender-icon.png';
import maleGenderIcon from '@/assets/male-gender-icon.png';
import profileDefault from '@/assets/user-placeholder.png';

export const StudentDetailsPage: React.FC = () => {
  const { studentCode } = useParams();
  const [activeTab, setActiveTab] = React.useState<'informations' | 'invoices'>('informations');
  const { community: communityRole, isAdmin, communityId } = useRole();

  const { data: community } = useSWR(
    [communityRole],
    () => apiClient.community.getCommunityDetails({ communityId: communityId ?? communityRole!.id }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  );

  const {
    data: studentData,
    isLoading: studentLoading,
    error: studentError
  } = useSWR(
    ['schools/find-student', communityId, studentCode],
    () => {
      if (community?.isInternalSchool) {
        return apiClient.student.getStudentDetails({ studentCode: studentCode! });
      } else {
        return apiClient.school.findStudent({
          communityId: communityId ?? communityRole!.id,
          studentCode: studentCode!
        });
      }
    },
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const {
    data: invoicesData,
    isLoading: invoicesLoading,
    error: invoicesError
  } = useSWR(
    /* TODO: implementar invoices para alunos de escola interna (isInternalSchool) */

    community?.isInternalSchool ? null : ['schools/get-student-invoices', communityId, studentCode],
    () =>
      apiClient.school.getStudentInvoices({ communityId: communityId ?? communityRole!.id, studentCode: studentCode! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const handleTabChange = (tab: 'informations' | 'invoices') => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <div className="flex items-center justify-between gap-4 py-10">
        <div className="flex items-center gap-5">
          <Link
            as={RouterLink}
            className="text-sm font-medium text-gray-500"
            to={isAdmin ? `/communities/${communityId}/students` : `/students`}
          >
            Students
          </Link>

          <ChevronRightIcon className="w-5 text-sm text-gray-400" />

          <LoadingOverlay {...{ error: studentError, loading: studentLoading }} displayLoader>
            <span className="text-sm font-medium text-[#ED6F4C]">{studentData?.name}</span>
          </LoadingOverlay>
        </div>

        <div className="flex items-center gap-2">
          <Navbar title="" />
        </div>
      </div>

      <LoadingOverlay {...{ error: studentError, loading: studentLoading }} displayLoader>
        <div className="mx-auto flex w-[90%] flex-col justify-between">
          <div className="flex items-start justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-5">
                <p>{studentData?.name}</p>
                <p
                  className={classNames(
                    'rounded-xl bg-opacity-10 px-2 font-bold hover:bg-opacity-30',
                    studentData?.parentStatus == 'active'
                      ? 'bg-[#52B642] text-[#52B642]'
                      : 'bg-[#DE3530] text-[#DE3530]'
                  )}
                >
                  {studentData?.parentStatus}
                </p>
              </div>

              <div
                className={classNames(
                  'flex w-max items-center rounded-full bg-opacity-10 px-2 font-semibold',
                  studentData?.gender == 'Female' ? 'bg-[#C34E68] text-[#C34E68]' : 'bg-[#257C9C] text-[#257C9C]'
                )}
              >
                <div>
                  <img alt="gender Icon" src={studentData?.gender == 'Female' ? femaleGenderIcon : maleGenderIcon} />
                </div>
                <p>{studentData?.gender}</p>
              </div>

              <div className={`flex items-center text-sm text-gray-900`}>
                <div className="h-10 w-10">
                  <img alt="Benefit logo" className="h-full w-full rounded-full object-cover" src={profileDefault} />
                </div>
                <p className="ml-2">
                  Guardian: {studentData?.motherName ? studentData.motherName : studentData?.fatherName}
                </p>
              </div>
            </div>

            {/* TODO: Solicitar updatedDate a orison */}

            {/*             
            <div className="flex items-center gap-5">
              <p className="opacity-60">Last modified date</p>
              <p className="font-normal">30/01/2025 | 9:36:00 AM</p>
            </div> 
            */}
          </div>
          <div className="my-8 flex gap-5">
            <button
              className={`px-6 py-2 ${
                activeTab === 'informations'
                  ? 'border-b-2 border-[#ED6F4C] text-[#ED6F4C]'
                  : 'text-primary border-primary border-b-2'
              }`}
              onClick={() => handleTabChange('informations')}
            >
              Informations
            </button>
            <button
              className={`px-6 py-2 ${
                activeTab === 'invoices'
                  ? 'border-b-2 border-[#ED6F4C] text-[#ED6F4C]'
                  : 'text-primary border-primary border-b-2'
              }`}
              onClick={() => handleTabChange('invoices')}
            >
              Invoices
            </button>
          </div>

          {activeTab == 'informations' ? (
            <div>
              <StudentInformationsList student={studentData!} />
            </div>
          ) : (
            <LoadingOverlay {...{ error: invoicesError, loading: invoicesLoading }} displayLoader>
              <StudentInvoicesList invoices={invoicesData!} />
            </LoadingOverlay>
          )}
        </div>
      </LoadingOverlay>
    </Layout>
  );
};
