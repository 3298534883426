/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GuardianPageDto } from '../models/GuardianPageDto';
import type { SchoolDetailsDto } from '../models/SchoolDetailsDto';
import type { SchoolImportSetDto } from '../models/SchoolImportSetDto';
import type { StudentDto } from '../models/StudentDto';
import type { StudentInvoiceDto } from '../models/StudentInvoiceDto';
import type { StudentPageDto } from '../models/StudentPageDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SchoolService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Set a external school to import.
   * @returns void 
   * @throws ApiError
   */
  public setImportSchool({
requestBody,
}: {
requestBody: SchoolImportSetDto,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/schools/import/set',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Import a school.
   * @returns void 
   * @throws ApiError
   */
  public importSchool({
communityId,
schoolCode,
}: {
communityId: string,
schoolCode: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/schools/import',
      query: {
        'communityId': communityId,
        'schoolCode': schoolCode,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Get details from school.
   * @returns SchoolDetailsDto A page of schools.
   * @throws ApiError
   */
  public getSchoolDetails({
communityId,
}: {
communityId: string,
}): CancelablePromise<SchoolDetailsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/schools/get-details',
      query: {
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

  /**
   * Return a page of students.
   * @returns StudentPageDto Returns a page of students.
   * @throws ApiError
   */
  public findStudents({
communityId,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * Id of the community.
 */
communityId: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<StudentPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/schools/get-students',
      query: {
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return a student.
   * @returns StudentDto Return a student.
   * @throws ApiError
   */
  public findStudent({
communityId,
studentCode,
}: {
/**
 * Id of the community.
 */
communityId: string,
/**
 * Code of the student.
 */
studentCode: string,
}): CancelablePromise<StudentDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/schools/find-student',
      query: {
        'communityId': communityId,
        'studentCode': studentCode,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return a student invoices.
   * @returns StudentInvoiceDto Return a student invoices.
   * @throws ApiError
   */
  public getStudentInvoices({
communityId,
studentCode,
}: {
/**
 * Id of the community.
 */
communityId: string,
/**
 * Code of the student.
 */
studentCode: string,
}): CancelablePromise<Array<StudentInvoiceDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/schools/get-student-invoices',
      query: {
        'communityId': communityId,
        'studentCode': studentCode,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Return a page of guardians with their children.
   * @returns GuardianPageDto Returns a page of guardians with their children.
   * @throws ApiError
   */
  public findGuardians({
communityId,
searchQuery,
pageSize,
pageNumber,
}: {
/**
 * Id of the community.
 */
communityId: string,
/**
 * text to search by.
 */
searchQuery?: string,
pageSize?: number,
pageNumber?: number,
}): CancelablePromise<GuardianPageDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/schools/get-guardians',
      query: {
        'communityId': communityId,
        'searchQuery': searchQuery,
        'pageSize': pageSize,
        'pageNumber': pageNumber,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
      },
    });
  }

  /**
   * Import guardians from school.
   * @returns void 
   * @throws ApiError
   */
  public importGuardians({
communityId,
}: {
communityId: string,
}): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/schools/import-guardians',
      query: {
        'communityId': communityId,
      },
      errors: {
        401: `Credentials are missing or invalid.`,
        404: `Resource not found.`,
      },
    });
  }

}
