import { Button } from '@/components/Button';
import { DebouncedInput } from '@/components/Form/DebouncedInput';
import { SearchInput } from '@/components/Form/SearchInput';
import { Layout } from '@/components/Layout';
import { Link } from '@/components/Link';
import { LoadingOverlay } from '@/components/Loading';
import { Navbar } from '@/components/Navbar';
import { Pagination } from '@/components/Pagination';
import { Column, DataTable } from '@/components/Table/DataTable';
import { page, search, size, status, statuses, types, useQueryParams } from '@/hooks/useQueryParams';
import { translations } from '@/locales';
import { apiClient } from '@/services/api';
import { GuardianDto } from '@billy/management-api-sdk';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSWR from 'swr';

const config = { page, search, size, status, statuses, types };

export const GuardiansListPage = () => {
  const [queryParams, updateQueryParams] = useQueryParams({ config });
  const { communityId } = useParams();

  const { data: community } = useSWR(
    ['get-community', communityId],
    () => apiClient.community.getCommunityDetails({ communityId: communityId! }),
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true
    }
  );

  const guardiansKey = `schools/find-guardians?page=${queryParams.page}&size=${queryParams.size}&status=${queryParams.status}&statuses=${queryParams.statuses}`;

  const {
    data: guardiansData,
    error: guardiansError,
    isLoading: guardiansLoading
  } = useSWR([guardiansKey, communityId], () => apiClient.school.findGuardians({ communityId: communityId! }));

  const columns: Column<GuardianDto>[] = [
    {
      accessor: 'name',
      cell: (guardian) => <p>{guardian.fullName}</p>,
      header: 'Name'
    },
    {
      accessor: 'students',
      cell: (guardian) => (
        <ul>
          {guardian.children.map((child) => (
            <li key={child.studentCode}>{child.childName}</li>
          ))}
        </ul>
      ),

      header: 'Students'
    },
    {
      accessor: 'dateAdded',
      cell: (guardian) => <p className="font-semibold">{new Date(guardian.createdAt).toLocaleDateString()}</p>,
      header: 'Date added'
    },
    {
      accessor: 'status',
      cell: (guardian) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <p
            className={classNames(
              'w-max rounded-full bg-opacity-10 px-4 py-1 font-semibold',
              guardian.active ? 'bg-[#52B642] text-[#52B642]' : 'bg-[#79747E] text-[#79747E]'
            )}
          >
            {guardian.active ? 'Active' : 'Inactive'}
          </p>
        </div>
      ),
      filter: {
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
          { label: 'Pending', value: 'pending' }
        ],
        type: 'multiple'
      },
      header: 'Status'
    },
    {
      accessor: 'groups',
      cell: (guardian) => (
        <p className="font-semibold text-[#ED6F4C]">
          {(guardian.groupsCount ?? 0) === 0 && '0 groups'}
          {(guardian.groupsCount ?? 0) === 1 && '1 group'}
          {(guardian.groupsCount ?? 0) > 1 && `${guardian.groupsCount} groups`}
        </p>
      ),
      header: 'Groups'
    },
    {
      accessor: 'actions',
      cell: (guardian) => (
        <div className={`whitespace-nowrap py-4 text-sm`}>
          <Button
            className={classNames(
              'w-full rounded-xl bg-opacity-10 px-8 py-2 font-bold hover:bg-opacity-30',
              guardian.active ? 'bg-[#DE3530] text-[#DE3530]' : 'bg-[#52B642] text-[#52B642]'
            )}
          >
            {guardian.active ? 'Suspend' : 'Reactivate'}
          </Button>
        </div>
      ),
      header: ''
    },
    {
      accessor: 'actions',
      cell: () => (
        <Button className="cursor-pointer font-semibold">
          <TrashIcon className="w-4 text-sm text-[#ED6F4C]" />
        </Button>
      ),
      header: ''
    }
  ];

  return (
    <>
      <Layout>
        <div className="mb-6 flex gap-5">
          <div className="mt-5 flex w-full justify-between">
            <div className="flex items-center">
              <Link as={RouterLink} className="text-sm font-medium text-gray-500" to="/communities">
                <FormattedMessage id={translations.pages.community.communities} />
              </Link>

              <ChevronRightIcon className="w-5 text-gray-400" />

              <Link as={RouterLink} className="text-sm font-medium text-gray-500" to={`/communities/${communityId}`}>
                {community?.name ?? ''}
              </Link>

              <ChevronRightIcon className="w-5 text-gray-400" />

              <Link as={RouterLink} className="text-sm font-medium text-[#ED6F4C]" to="#">
                Guardians
              </Link>
            </div>
            <div>
              <Navbar title="" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold">Guardians ({guardiansData?.page.total ?? 0})</h2>
          <DebouncedInput
            as={SearchInput}
            className="w-[22.5rem]"
            initialValue={queryParams.search}
            onChange={(search: string) => updateQueryParams({ page: 1, search })}
            placeholder={'Search by name, guardian, code, class or division'}
          />
        </div>
        <LoadingOverlay {...{ error: guardiansError, loading: guardiansLoading }}>
          <DataTable columns={columns} data={guardiansData?.items ?? []} />
          {!!guardiansData && (
            <Pagination
              className="mt-9"
              currentPage={guardiansData.page.pageNumber}
              onPageChange={(page: number) => updateQueryParams({ page })}
              pageSize={guardiansData.page.pageSize}
              totalItems={guardiansData.page.total}
            />
          )}
        </LoadingOverlay>
      </Layout>
    </>
  );
};
