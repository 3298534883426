import React from 'react';

import { Auth } from '@/containers/use-auth';

export const useRole = () => {
  const { context } = Auth.useContainer();

  const isAdmin = !!context?.role?.isAdmin;
  const communityId = localStorage.getItem('communityId');

  const communities = context?.role?.communityManager?.communities;
  const community = communityId ? communities?.find((_community) => _community.id === communityId) : communities?.[0];

  return React.useMemo(
    () => ({ communities, community, communityId, isAdmin }),
    [communities, community, isAdmin, communityId]
  );
};
